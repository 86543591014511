.rowHead {
  display: flex;
  justify-content: space-between;
  width: 375px;
  margin: 0 0 10px;
  border-bottom: 0.5px solid #0e1b42;
}

.button_arrow {
  background-color: #4f004f;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload_options {
  width: 100%;
  max-width: 405px;
}

.add-portfolio-popup .Holder {
  box-shadow: none;
}

.Holder {
  max-width: 450px;
  width: 100%;
  text-align: center;
  box-shadow: 0 8px 28px -6px rgba(24, 39, 75, 0.25),
    0 18px 88px -4px rgba(24, 39, 75, 0.14);
  border-radius: 4px;
  row-gap: 16px;
  width: 100%;
}

.Holder h3 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  text-align: left;
  color: var(--Primary);
  margin: 0;
  padding: 16px 24px;
  background: #f5f5f5;
  width: 100%;
}

.port-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.port-options a {
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  border-bottom: 1px rgba(24, 39, 75, 0.07) solid;
  max-width: 320px;
  column-gap: 16px;
  width: 100%;
}

.port-options label {
  display: inline-flex;
  flex-direction: row;
  padding: 8px 0;
  border-bottom: 1px rgba(24, 39, 75, 0.07) solid;
  max-width: 350px;
  column-gap: 160px;
  width: 100%;
}

.port-options label p {
  color: var(--Navy-Blue);
  align-items: center;
}

.port-options a:last-child {
  border-bottom: none;
}

.port-options a p {
  display: flex;
  margin: 0;
  flex: 1;
  color: var(--Navy-Blue);
  align-items: center;
}

.closepopup {
  position: absolute;
}