.bankinstructions {
    display: flex;
    flex-direction: column;
    width: 100%;
    column-gap: 16px;
    row-gap: 16px;
    color: var(--Primary);
    font-weight: 600;

    .paymentcard {
        display: flex;
        width: 100%;
        padding: 16px;
        background: var(--bg-gray);
        align-items: center;
        border-radius: 8px;
    }

    .portfolioname {
        display: flex;
        min-width: 200px;
    }

    .accountnum {
        display: flex;
        flex: 1;
    }

    .action {
        display: flex;
        column-gap: 24px;

        img {
            width: 24px;
            height: 24px;
        }
    }
}