.portfolio_page-holder {
  background-color: var(--White);
  padding: 32px;
  /* box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}

.addPortfolioButton {
  padding-right: 40px;
}

@media(max-width:768px) {
  .portfolio_page-holder {
    padding: 16px;
  }
}