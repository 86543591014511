.heading_text_errors {
  font-family: Inter;
  color: var(--Navy-Blue);
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 20px;
}


.errors_table {
  width: 100%;
  border-collapse: collapse;
}

.errors_table_holder {
  width: 95%;
  margin: auto;
  overflow-y: scroll;
  max-height: 300px;
  background: white;
  margin-top: 20px;
  flex-wrap: wrap;

}

.errors_table thead {
  border-bottom: 1px solid #f2f2f2;
  height: 40px;
  text-align: left;
}

.errors_table th {
  color: #60034C;
  font-weight: 700;
  letter-spacing: 1px;
  white-space: nowrap;
  margin-left: 20px;
}

.errors_table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.54);
  margin-left: 8px;
}

.errors_table tr {
  text-align: left;
  height: 40px;
  text-transform: uppercase;
}

.errors_table td {
  padding: 0 0 0 0;
  color: var(--Navy-Blue);
  font-weight: 500;
  letter-spacing: 1px;
  white-space: nowrap;
}

.errors_table tr:nth-child(even) {
  background-color: #fafafa;
}