.details_table {
  width: 200%;
  /* width: 100%; */
  padding-left: 30px;
  padding-right: 10px;
  border-collapse: separate;
  border-spacing: 10px;
  box-sizing: border-box;
}

.details_table-2 {
  width: 200%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 10px;
  box-sizing: border-box;
}

.details_table-3 {
  width: 150%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 10px;
  box-sizing: border-box;
}

.details_table-4 {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 10px;
  box-sizing: border-box;
}

.details_table th {
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #60034c;
  text-align: left;
}

.details_table-2 th {
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0px;
  color: #60034c;
  text-align: left;
}

.details_table-3 th {
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0px;
  color: #60034c;
  text-align: left;
}

.details_table-4 th {
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0px;
  color: #60034c;
  text-align: left;
}

.details_table-4 th {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0px;
  color: #60034c;
  text-align: left;
}

.details_table-4 th {
  font-family: Inter;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0px;
  color: #60034c;
  text-align: left;
}

.details_table td {
  font-family: Inter;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1px;
  color: #263154;
}

.details_table-2 td {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0px;
  color: #263154;
}

.details_table-3 td {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0px;
  color: #263154;
}

.details_table-4 td {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0px;
  color: #263154;
}

.details_wrap {
  display: flex;
  justify-content: space-between;
}

/* .details_table tr td{
    padding: 5px;
    border-right: 1px dotted #4d4c4c;
  }

.details_table tr td:last-child{
    border-right: none;
  } */

/* .button_wrapper{
    display: flex;
    margin-top: 10px;
    padding-right: 30px;
    padding-left: 30px;
    margin-left: 30%;
} */

.delete_button {
  padding-left: 30px;
}

.subnavbar {
  display: inline-flex;
  position: relative;
  padding: 0 0;
  flex-direction: row;
}

@media (max-width: 959px) {
  .subnavbar {
    background: var(--Peach);
  }
}

.subnavbar-menu {
  margin: 0;
  display: flex;
  column-gap: 32px;
  align-items: center;
  height: 100%;

}

.navtab.subnavbar-menu {
  position: absolute;
  bottom: 0;
  top: auto;
  height: 40px;
  left: 32px;
  z-index: 2;
}

.navtab.subnavbar-menu .SUBITEM,
.navtab.subnavbar-menu .SUBITEM-selected {
  padding: 8px 0px;
}

.SUBITEM,
.SUBITEM-selected {
  display: block;
  text-align: center;
  color: #555;
  padding: 18px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  position: relative;
}

.SUBITEM-selected {
  color: var(--Primary);
  border-bottom: 3px solid var(--Primary);
  border-radius: 0px;
}

.SUBITEM:hover {
  color: var(--Primary);
}

/* .SUBITEM-selected {
  background: var(--Secondary);
  color: var(--White);
} */

.SUBITEM-selected:after {
  background-color: var(--Primary);
}

@media (max-width: 480px) {
  .subnavbar {
    padding: 0;
    overflow: auto;
  }

  .subnavbar-menu {
    column-gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.last_updated_text {
  display: block;
  text-align: center;
  color: var(--Primary);
  padding: 8px 16px;
  font-family: "Inter";
  font-weight: 800;
  font-size: 15px;
  position: relative;
  flex-direction: column;
  margin: 0;
}