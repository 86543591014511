.main-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
}

.addButton {
  display: block;
  width: 30px;
  height: 30px;
}

.addButton img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.page-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
}

.page-header {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  color: #263154;
  margin: 0;
}

.page-header-with-back-button {
  display: flex;
  align-items: center;
}

.page-header-with-back-button a {
  margin-right: 24px;
  background: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-header-with-back-button a .back_button {
  font-size: 30px;
}

.choose-popup a {
  display: block;
  width: 30px;
  height: 30px;
}

.confirm-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
  color: var(--White);
  background: var(--Primary);
  padding: 16px;
  width: 100%;
}

.choose-popup a img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.add-portfolio-popup {
  background-color: var(--White);
  border-radius: 4px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100vh;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 940px) {
  .page-header-wrapper {
    padding: 30px 15px;
  }
}

.export-button {
  display: flex;
  align-items: center;
  height: 50px;
  max-height: 50px;
  font-size: 14px;
  padding: 0px 40px;
  border-radius: var(--button-border-radius);
  border: 1px solid;
  font-family: "Inter";
  font-weight: 700;
  cursor: pointer;
  box-sizing: border-box;
  background-color: var(--Primary);
  color: var(--White);
}

.bubble {
  background: var(--Secondary);
  color: var(--Primary);
  font-size: 10px;
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
}

.add-portfolio-popup .closepopup {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media(max-width:959px) {
  .page-header-with-back-button a {
    margin-right: 16px;
  }
}