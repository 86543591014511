.withdraw-container {
    background-color: var(--White);
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    grid-column-gap: 24px;
    column-gap: 24px;
    grid-row-gap: 24px;
    row-gap: 24px;
    flex: 1;
    overflow: auto;
}

.ai-content-part {
    width: 100%;
    height: auto;

}

.balance {
    background: var(--bg-primary);
    padding: 16px 16px 16px 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 4px;

    h3 {
        font-size: 18px;
        color: var(--Navy-Blue);
    }

    .available_balance {
        background: var(--White);
        color: var(--Primary);
        font-size: 20px;
        padding: 16px 32px;
        border-radius: 4px;
        font-weight: 700;
    }
}

.ai-form-group {
    flex-direction: row;
    column-gap: 48px;
    flex-wrap: wrap;
    margin-top: 32px;

    .form-container {
        max-width: 350px;

        .form-label-light {
            color: var(--Grey);
            font-size: 16px;
            text-transform: uppercase;
        }

        .lineinput {
            display: flex;
            flex-direction: row;
            column-gap: 16px;

            .ai-btn {
                padding: 12px 16px;
                margin-top: 10px;

                img {
                    max-height: 24px;
                    height: 24px;
                }
            }
        }

        @media screen and (max-width: 768px) {
            max-width: 100%;
        }
    }
}

.instructions {
    margin-top: 0;

    h3 {
        color: var(--Primary);
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    p {
        color: var(--Navy-Blue);
        margin-top: 0px;
    }

    .deposit {
        width: 100%;
        max-width: 100%;
        flex-direction: row;
        column-gap: 32px;
        padding: 16px 32px;
        margin-top: 24px !important;

        .form-container {
            max-width: 270px;

            select {
                padding-right: 16px;
            }

            @media screen and (max-width: 768px) {
                max-width: 100%;
            }
        }

        .form-label-light {
            color: var(--Black);
            font-weight: 600;
            text-transform: none;
        }

        .ai-btn {
            margin-top: 36px;
            padding: 14px;

            @media screen and (max-width: 768px) {
                margin-top: 0;
            }
        }
    }

    .viewfield {
        position: relative;

        .showtext {
            position: absolute;
            right: 10px;
            top: 18px;
        }

        .hide {
            display: none;
        }
    }

    .invalidMessage {
        font-size: 14px;
        color: var(--Error);
        position: relative;
        margin-top: 0;
        right: 0;
    }
}