.form-div {
  background-color: white;
  width: 100%;
  height: 100%;
}

.header-div {
  display: flex;
  background-color: #fbece6;
  padding: 1% 0 1% 0;
}

.header-form {
  color: #e58b76;
  text-align: center;
  margin-top: 2.75%;
}

.box-container {
  position: relative;
  left: 50%; /* center on x direction */
  transform: translateX(-50%);
  width: 23.5vw;
  padding: 0 0.5% 1.5% 0%;
  background-color: rgba(229, 139, 118, 0.1);
  border-radius: 16px;
}

.form-text {
  color: var(--Primary);
  margin-top: 2%;
  font-size: 13px;
  font-family: "Inter";
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 13px;
  text-transform: uppercase;
  margin-left: 13%;
  padding-top: 3%;
}

.input-text-box-adaptive {
  margin-top: -1.5%;
  width: 80%;
  max-height: 4px;
  background-color: #e58b76;
  background: rgba(229, 139, 118, 0.1);
  padding: 24px 25px 25px 18px;
  border: 1px dashed #666666;
  border-radius: 5px;
  resize: none;
  font-size: 14px;
  overflow: hidden;
  box-sizing: border-box;
  margin-left: 13%;
}

.input-text-box {
  margin-top: -1.5%;
  width: 80%;
  max-height: 4px;
  background-color: white;
  padding: 24px 25px 25px 18px;
  border: 1px dashed #666666;
  border-radius: 5px;
  resize: none;
  font-size: 14px;
  overflow: hidden;
  box-sizing: border-box;
  margin-left: 13%;
}

.button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
  margin-top: 8px;
  padding-bottom: 1.75%;
  background: none;
}

.invalidMessage {
  font-size: 14px;
  color: #ff0000;
  position: absolute;
  margin-top: 0;
  right: 0;
}
