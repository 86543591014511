.pay-success_page-holder {
    background-color: var(--White);
    padding: 32px;
    /* box-shadow: 0px -2px 30px rgb(0 0 0 / 10%); */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1;
    flex-direction: column;
    grid-row-gap: 32px;
    grid-row-gap: 32px;
    row-gap: 32px;
  }
  
  .successful-holder {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    position: relative;
    margin: auto;
    align-items: center;
    width: fit-content;
    width: 100%;
    row-gap: 32px;
  }
  
  .pay-success-circle {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    background-color: #e58b76;
    height: 100px;
    width: 100px;
    opacity: 0.9;
    text-align: center;
  }
  
  .pay-success_img {
    padding-top: 20%;
    width: 50%;
  }
  
  .active-message {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.266px;
    color: var(--Navy-Blue);
    text-align: center;
  }
  
  .download-contract-button {
    width: 300px;
    height: 60px;
    margin-top: 30px;
    border-radius: 32px;
    background-color: #60034c;
  }
  
  .buttonText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: var(--White);
  }
  
  .success-box {
    display: flex;
    column-gap: 24px;
    flex-wrap: wrap;
  }
  
  .active-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .goback {
    display: flex;
    align-items: center;
  }
  
  .back-to-portfolio {
    display: flex;
    align-items: center;
    letter-spacing: -0.266px;
    text-decoration-line: underline;
    color: #e58b76;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    margin: 0;
    font-weight: 600;
  }
  
  .pdf-content {
    background: var(--White);
    padding: 48px;
    margin: 48px;
    border-radius: 8px;
    width: calc(100% - 96px);
  }
  
  .header-holder {
    padding-bottom: 30px;
  }
  
  .name-img {
    height: 80px;
  }
  
  .payment-header {
    margin: 12px 0;
    padding: 0;
    font-size: 30px;
    color: var(--Navy-Blue);
    font-family: "Inter";
    font-weight: 600;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  
  .payment-header-p {
    font-size: 20px;
    color: black;
    font-family: "Inter";
  }
  
  .payment-details-table table,
  .payment-details-table-2 table,
  .payment-details-table-3 table {
    border-collapse: separate;
    border-spacing: 0 8px;
  }
  
  .payment-details-table th,
  .payment-details-table-2 th,
  .payment-details-table-3 th {
    font-size: 20px;
    color: #60034c;
    text-align: left;
  }
  
  .payment-details-table td,
  .payment-details-table-2 td,
  .payment-details-table-3 td {
    font-size: 20px;
    color: #666666;
    padding-right: 22px;
    line-height: 22px;
    letter-spacing: -0.266px;
  }
  
  .payment-details-table td:nth-child(3),
  .payment-details-table td:nth-child(6),
  .payment-details-table-2 td:nth-child(3),
  .payment-details-table-2 td:nth-child(6),
  .payment-details-table-3 td:nth-child(3),
  .payment-details-table-3 td:nth-child(6) {
    font-weight: 700;
  }
  
  .payment-TC {
    color: var(--Navy-Blue);
    text-align: left;
    padding: 24px;
    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 10px;
  }
  
  .payment-TC p {
    margin: 0;
    line-height: 1.6;
  }
  
  .payment-TC-text {
    padding: 0px 24px 14px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.266px;
    color: #666666;
  }
  
  .payment-stocksummary {
    border: 1px solid black;
    margin-top: 32px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .payment-stocksummary table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 6px 12px;
    table-layout: fixed;
    border-collapse: collapse;
  }
  
  .payment-stocksummary table thead {
    display: block;
    background: #fbece6;
    border-radius: 8px 8px 0px 0px;
  }
  
  .payment-stocksummary table thead tr th {
    text-transform: uppercase;
    font-size: 16px;
    color: var(--Primary);
    font-family: "Inter";
    font-weight: 600;
    white-space: nowrap;
    width: 17.5%;
    text-align: center;
  }
  
  .payment-stocksummary table tbody th {
    width: 5%;
    padding: 10px 0 0 0;
    font-weight: 600;
    font-size: 16px;
    color: black;
    text-align: center;
  }
  
  .payment-stocksummary table tbody td {
    width: 5%;
    padding: 10px 0 10px 0;
  }
  
  .payment-stocksummary table tbody::-webkit-scrollbar {
    width: 8px;
    background-color: var(--Peach);
    border-radius: 8px;
    box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.54);
    margin-left: 8px;
  }
  
  .payment-stocksummary table tbody::-webkit-scrollbar-thumb {
    background-color: var(--Primary);
    border-radius: 8px;
  }
  
  /* .payment-stocksummary table tbody tr {
      display: block;
  } */
  
  .payment-stocksummary table tbody tr:nth-child(even) {
    background: #fafafa;
  }
  
  .payment-stocksummary table tbody tr:last-child {
    border-bottom: none;
  }
  
  .payment-stocksummary table tbody tr td {
    color: #5b5b5b;
    font-size: 20px;
  }
  
  .payment-stocksummary table thead tr th,
  .payment-stocksummary table tbody tr td {
    text-align: center;
  }
  
  .payment-stocksummary table thead tr th:nth-child(1) {
    padding: 10px;
  }
  
  .payment-stocksummary table tbody tr td:nth-child(1),
  .payment-stocksummary table tbody tr td:nth-child(2) {
    text-align: center;
  }
  
  .payment-stocksummary table tbody tr td:nth-child(3),
  .payment-stocksummary table tbody tr td:nth-child(4) {
    text-align: center;
  }
  
  .payment-stocksummary table tbody tr td:nth-child(1),
  .payment-stocksummary table tbody tr td:nth-child(4) {
    color: black;
    font-weight: 700;
  }
  
  .payment-details-table {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
  }
  
  .payment-checkout-details {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .payment-checkout-details label {
    color: black;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    min-width: 165px;
  }
  
  .payment-checkout-details .col-sap {
    padding: 0 8px;
  }
  
  .payment-checkout-details .pay-value {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
  
  .user-checkout-details {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .user-checkout-details label {
    color: black;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    min-width: 165px;
  }
  
  .user-checkout-details .col-sap {
    padding: 0 8px;
  }
  
  .user-checkout-details .pay-value {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
  
  .fillcontent,
  .fillcontent .popupcontentpart {
    max-width: 100%;
    width: 100%;
    padding: 0;
    flex: 1;
  }
  
  .fillcontent .popupcontentpart {
    display: flex;
    grid-column-gap: 32px;
    column-gap: 32px;
    overflow: visible;
    height: auto;
    flex: 1;
  }
  
  .fillcontent .popupcontentpart h4 {
    margin-top: 0;
  }
  
  @media (max-width: 992px) {
    .pay-success_page-holder {
      padding: 24px;
    }
  }
  
  @media (max-width: 768px) {
    .pay-success_page-holder {
      padding: 24px 8px;
    }
  }