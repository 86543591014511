.csvnavbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (max-width: 959px) {
  .csvnavbar {
    background: var(--Peach);
  }
}

.csvnavbar-menu {
  list-style: none;
  margin: 0 0 0 45px;
  padding: 0;
  display: flex;
  column-gap: 32px;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  flex: 1;
}

.noborder {
  border: none;

}

.portsolution {
  width: 100%;

  .csvnavbar-menu {
    margin: 0;
    padding: 0 32px;
    border-bottom: 1px #E0D1DC solid;
  }
}

.lineinput {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  column-gap: 16px;
}
#protection-level[disabled] {
  pointer-events: none;
  opacity: 0.7;
}