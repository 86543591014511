.adaptive-genius-holder {
  width: 100%;
  height: calc(100vh - 175px);
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-color: var(--White);
  border-radius: 24px 24px 0 0;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.1);
}

.adaptive-genius-icons {
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  align-items: center;
  width: fit-content;
}

.adaptive-genius-holder_vertical {
  width: 100%;
  height: calc(100% - 210px);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--White);
  border-radius: 24px 24px 0 0;
  overflow: hidden;
  box-sizing: border-box;
}
