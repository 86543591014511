.LoginScreenBody {
  overflow-y: hidden;
}

.LeftDiv {
  background-color: #60034c;
  width: 596px;
  height: 1022px;
}

.LeftDiv-2 {
  background-color: #60034c;
  width: 456px;
  height: 1022px;
}

.LeftDiv-3 {
  margin-top: 20px;
  text-align: center;
}

.LeftDiv-4 {
  margin-top: 20px;
  text-align: center;
}

.RightDiv {
  margin-left: 596px;
  height: 750px;
  margin-top: -1000px;
}

.RightDiv-2 {
  margin-left: 456px;
  height: 750px;
  margin-top: -1000px;
}

.RightDiv-3 {
  margin: auto;
  height: 750px;
  min-width: 300px;
  max-width: 700px;
  align-items: center;
}

.loginWindow {
  margin-top: 200px;
  margin-left: 90px;
}

.loginWindow-2 {
  margin-top: 200px;
  margin-left: 50px;
}

.loginWindow-3 {
  margin-top: 40px;
}

.loginWindow-4 {
  margin-top: 40px;
}

.logo {
  margin-left: 57px;
  margin-top: 57px;
}

.messageBox {
  margin: auto;
  width: 400px;
  margin-top: 108px;
}

.fineText {
  margin-top: 50px;
  margin-left: 25px;
  margin-right: 25px;
  float: bottom;
}

.fineText-2 {
  /* margin-top: 70px; */
  margin-left: 25px;
  margin-right: 25px;
  float: bottom;
}

.fineText-3 {
  margin: auto;
  margin-top: 70px;
}

.fineText-4 {
  margin: auto;
  margin-top: 70px;
}

.notes {
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #60034c;
}

.disclaimer {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: var(--Navy-Blue);
}

.tnc_privacy {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #60034c;
}