.ai-backtest-slider {
  margin: 20px 0;
}

.ai-backtest-slider .ai-backtest-event {
  display: block;
  text-align: center;
  padding: 14px 0;
}

.ai-backtest-slider .slick-track > div {
  border-right: 1px dotted rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}

.ai-backtest-slider .slick-track > div:last-child {
  border-right: none;
}

.ai-backtest-event p {
  font-size: 14px;
  margin: 0 0 8px 0;
}

.ai-backtest-event .ai-event-timestamp {
  margin: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.ai-slide-next,
.ai-slide-next:hover {
  background-image: url("../../images/arrowleft.png");
  background-repeat: no-repeat;
  background-size: cover;
  right: 7px;
  background-color: transparent;
  text-indent: -9999px;
  z-index: 10;
  margin-top: -12px;
}

.ai-slide-prev,
.ai-slide-prev:hover {
  background-image: url("../../images/arrowright.png");
  background-repeat: no-repeat;
  background-size: cover;
  left: 7px;
  background-color: transparent;
  text-indent: -9999px;
  z-index: 10;
  margin-top: -12px;
}
@media screen and (max-width: 680px) {
  .details_wrap .infocard {
    flex-direction: column;
    align-items: flex-start;
  }
  .ai-date-selection {
    flex-direction: column;
  }
  .ai-date-selection p {
    margin: 0;
  }
}
