/* Banner odd  */

.banner-odd-lg {
  width: 320px;
  background-color: #e68b76;
  border-radius: 24px;
  margin: 0 30px;
  padding: 15px 30px;
  opacity: 0.8;
}

.banner-odd-lg:hover {
  opacity: 1;
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}

.banner-odd-lg-md {
  width: 246px;
  background-color: #e68b76;
  border-radius: 18.5px;
  margin: 0 24px;
  padding: 12px 24px;
  opacity: 0.8;
}

.banner-odd-lg-md:hover {
  opacity: 1;
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}

/* Banner Even  */

.banner-even-lg {
  width: 320px;
  background-color: #63a0c5;
  border-radius: 24px;
  margin: 0 30px;
  padding: 15px 30px;
  opacity: 0.8;
}

.banner-even-lg:hover {
  opacity: 1;
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}

.banner-even-lg-md {
  width: 246px;
  background-color: #63a0c5;
  border-radius: 24px;
  margin: 0 24px;
  padding: 12px 24px;
  opacity: 0.8;
}

.banner-even-lg-md:hover {
  opacity: 1;
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}

/* Recommendation Holder */

.recommendation-holder-lg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recommendation-holder-lg-md {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recommendation-holder-sm-xs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Recommendation Title */

.recommendation-title-lg {
  color: white;
  font-weight: 700;
  font-size: 13px;
  font-stretch: normal;
  letter-spacing: 1px;
  text-align: left;
  margin-bottom: 14px;
}

.recommendation-title-lg-md {
  color: white;
  font-weight: 700;
  font-size: 10px;
  font-stretch: normal;
  letter-spacing: 0.77px;
  text-align: left;
  margin-bottom: 10px;
}

/* Message Panel */

.message-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
}

.message {
  font-size: 16px;
  letter-spacing: -0.18px;
  color: white;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.msg-plan {
  font-size: 14px;
}

.msg-week {
  font-size: 23px;
  font-weight: 700;
  letter-spacing: -0.14px;
  color: white;
  text-align: left;
  line-height: 28px;
  text-transform: uppercase;
}

.msg-percent {
  font-style: normal;
  font-weight: 800;
  font-size: 23px;
  line-height: 28px;
  letter-spacing: -0.178571px;
  color: #60034c;
}

.recommendation-card-2 .msg-percent {
  color: #e58b76;
}

.recommendation-card-3 .msg-percent {
  color: #ffa654;
}

/* Money Image */

.money-image-lg {
  margin-left: auto;
}

.money-image-lg-md {
  margin-left: auto;
}

/* Coin Image */

.coin-image-lg {
  /* width: 100px; */
  height: 50px;
}

.coin-image-lg-md {
  /* width: 77px; */
  height: 50px;
}

/* Description */

.description-lg {
  font-size: 16px;
  color: var(--Navy-Blue);
  letter-spacing: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.2;
  margin-bottom: 20px;
}

.description-lg-md {
  font-size: 12.3px;
  color: var(--Navy-Blue);
  letter-spacing: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0.92;
  margin-bottom: 15.4px;
}

/* Cost Saving */

.cost-saving {
  font-weight: 700;
  align-items: center;
  color: var(--White);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.you-save-1 {
  font-weight: 700;
  color: #263154;
  font-size: 16px;
  line-height: 20px;
}

.you-save-2 {
  font-weight: 700;
  color: #e58b76;
  font-size: 16px;
  line-height: 20px;
}

.you-save-3 {
  font-weight: 700;
  color: #ffa654;
  font-size: 16px;
  line-height: 20px;
}

.recommendation-total {
  color: #263154;
  line-height: 36px;
  font-size: 30px;
  font-weight: 700;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: auto;
  right: 0;
  height: 68px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px 0px 16px 0px;
  padding: 0 16px;
  bottom: 0;
}

.cost-saved {
  position: relative;
}

.cost-saved:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--White);
  top: 8px;
  left: 0;
  transform: rotate(-1deg);
}

.recommendation_wrap {
  display: flex;
  flex: 1;
  padding: 32px;
  flex-wrap: wrap;
  grid-row-gap: 48px;
  row-gap: 48px;
  grid-column-gap: 48px;
  column-gap: 48px;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.recommendation_page {
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  width: fit-content;
}

.recommendation-banner-lg {
  max-width: 100%;
  width: 320px;
  position: relative;
}

.recommendation-banner-lg:hover {
  opacity: 1;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
}

.recommendation_page .MuiGrid-container {
  display: flex;
  column-gap: 48px;
  row-gap: 48px;
  max-width: 100%;
}

.recommendation_page .MuiGrid-root {
  margin: 0;
  padding: 0;
}

.recommendation_page .MuiGrid-item,
.recommendation_page .MuiGrid-item a {
  display: flex;
  max-width: 100%;
}

.recommendation_page .MuiGrid-container .MuiGrid-item {
  padding: 0;
}

.recommendation-banner-lg .recommendation-card {
  background: #e68b76;
  padding: 0px 24px 86px;
  border-radius: 16px;
  position: relative;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  height: 100%;
}

.recommendation-banner-lg .recommendation-card-2 {
  background: #60034c;
  /* padding: 0px 25px 24px 0; */
  border-radius: 16px;
  position: relative;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  height: 100%;
}

.recommendation-banner-lg .recommendation-card-3 {
  background: #333333;
  /* padding: 0px 25px 24px 0; */
  border-radius: 16px;
  position: relative;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  height: 100%;
}

.recommendation-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
}

.recommendation-title-3 {
  background: linear-gradient(0deg, #000000, #000000);
}

.position-new {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--White);
}

.risk-message-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #222222;
}

.recommendation-card-2 .risk-message-text,
.recommendation-card-3 .risk-message-text {
  color: var(--White);
}