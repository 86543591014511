.plaid-tabs.nav-tabs {
  border-bottom: none;
  margin-bottom: 0;
}

.plaid-tabs.nav-tabs .nav-item {
  position: relative;
  margin-right: 15px;
}

.plaid-tabs.nav {
  display: flex;
  border-bottom: 1px #dee2e6 solid;
}
.sheild-summary-wrapper {
  width: 100%;
}
.sheild-summary-wrapper .tab-content {
  padding-top: 8px;
  margin: 0;
  border: 1px #dee2e6 solid;
  border-top: none;
  overflow: auto;
}

.plaid-tabs.tab-content {
  border-left: 1px solid rgb(143, 11, 11);
  border-right: 1px solid #ddd;
  padding: 10px;
}

.plaid-tabs.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.plaid-tabs.nav-tabs .nav-item button,
.plaid-tabs.nav-tabs .nav-item {
  appearance: none;
  background: transparent;
  background-color: #fbece6;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.289231px;
  color: #000000;
}

.plaid-tabs li:last-child {
  margin-right: 0;
}

.plaid-tabs.nav-tabs .nav-item button.active:after,
.plaid-tabs.nav-tabs .nav-item.active:after {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  /* background-color: var(--Primary); */
}

.plaid-info img {
  margin-top: 10px;
}
.plaid-info text {
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.266px;
  color: #263154;
  font-size: 14px;
  /* padding-left: 10px; */
}

.plaid-info p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #263154;
}

.plaid-info div {
  justify-content: center;
  align-items: center;
  background: #f7f0f5;
  border: 1px solid #efe6ed;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 20px;
}

.ai-accountsummary th,
.ai-accountsummary td {
  padding: 8px 24px;
  text-align: left;
  line-height: 18px;
  font-size: 14px;
}
