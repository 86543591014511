.shieldcompare_page {
    background-color: var(--White);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;

    .shildcompare {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 24px 32px;

        .shildhead {
            display: flex;
            width: 100%;
            padding-top: 16px;

            div {
                width: 14.28%;
                font-size: 13px;
                font-weight: 700;
                line-height: 1.54;
                padding: 10px 16px;
                color: #60034c;
                text-transform: uppercase;
                justify-content: center;
                align-items: center;
                align-content: center;
                text-align: center;

                &.left {
                    text-align: left;
                }
            }
        }

        .shieldcard {
            display: flex;
            width: 100%;
            align-items: center;
            padding: 16px 0;

            &:nth-child(2n+1) {
                background: #FAFAFA;
            }

            div {
                width: 14.28%;
                font-size: 13px;
                line-height: 1.54;
                padding: 10px 16px;
                justify-content: center;
                align-items: center;
                align-content: center;
                text-align: center;
                font-weight: 500;

                &.left {
                    text-align: left;
                    text-transform: uppercase;
                }

                &.graph {
                    display: flex;
                }
            }
        }
    }
}

.page_filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #E0D1DC solid;
    padding: 24px 32px;

    .det_sel {
        display: flex;
        white-space: nowrap;
        column-gap: 24px;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 8px;

        .ai-percent-value {
            font-weight: 600;
        }

        .infotext{
            font-size: 12px;
            max-width: 100%;
            margin-top: 10px;
            display: inline-block;
            white-space: wrap;
        }

        select {
            border: none;
            font-size: 18px;
            width: 110px;
            padding: 4px 0;
            border-bottom: 2px var(--Secondary) solid;
            color: var(--Secondary);
            line-height: 20px;
            height: 32px;

            &.ai-form-full {
                width: 100%;
                font-size: 14px;
            }

            &:focus {
                outline: none;
            }

            option {
                font-size: 14px;
                color: var(--Black);
            }
        }
    }

    .timer_sec {
        display: flex;
        column-gap: 48px;
    }

}

.notice {
    width: 100%;
    display: block;
}

.infocard .balance {
    padding: 0;
}

.infocard .balance .available_balance {
    width: 100%;
    padding: 8px 0;
}