.ai-selfassembly-table {
  background: var(--White);
  display: flex;
  flex: 1;
  width: 100%;
  overflow: auto;
}

.ai-selfassembly-table table {
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
}

.ai-selfassembly-table table thead tr th {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--Primary);
  font-family: "Inter";
  font-weight: 700;
  white-space: nowrap;
}

.ai-selfassembly-table table tbody {
  overflow-y: auto;
}

.ai-selfassembly-table table tbody::-webkit-scrollbar {
  width: 8px;
  background-color: var(--Peach);
  border-radius: 8px;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.54);
  margin-left: 8px;
}

.ai-selfassembly-table table tbody::-webkit-scrollbar-thumb {
  background-color: var(--Primary);
  border-radius: 8px;
}

.ai-selfassembly-table table tbody tr td {
  color: #5b5b5b;
  font-size: 14px;
}

.ai-selfassembly-table table thead tr th,
.ai-selfassembly-table table tbody tr td {
  text-align: right;
  padding: 16px;
  white-space: nowrap;
}

.ai-selfassembly-table table tbody tr:nth-child(2n + 1) {
  background: #fafafa;
}

.ai-selfassembly-table table thead tr th:nth-child(1),
.ai-selfassembly-table table tbody tr td:nth-child(1) {
  font-weight: 700;
  color: var(--Primary);
}

.ai-selfassembly-table table thead tr th:nth-child(1),
.ai-selfassembly-table table tbody tr td:nth-child(1),
.ai-selfassembly-table table thead tr th:nth-child(2),
.ai-selfassembly-table table tbody tr td:nth-child(2) {
  text-align: left;
}

.ai-selfassembly-table table tbody tr td:nth-child(2) {
  font-family: "Inter";
  font-weight: 700;
}

.ai-selfassembly-table .ai-selfassembly-cost {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 24px;
}

.ai-selfassembly-table .ai-selfassembly-cost p {
  margin: 0 0 8px 0;
  color: #5b5b5b;
}

.ai-selfassembly-table .ai-selfassembly-cost p span {
  font-family: "Inter";
  font-weight: 600;
}

.ai-selfassembly-table .ai-selfassembly-cost .ai-btn {
  height: 38px;
  max-height: 38px;
}

.selfassambly-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ai-selfassembly-cost {
  display: flex;
  padding: 8px 16px;
  flex-wrap: wrap;
}

.ai-selfassembly-cost p {
  display: flex;
  flex: 1;
  font-size: 18px;
}

.ai-selfassembly-cost button {
  min-width: 120px;
  align-items: center;
  justify-content: center;
}

.price-Guaranteed {
  color: var(--Primary);
  padding-left: 8px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .ai-selfassembly-cost {
    flex-direction: column;
  }
}