.backtestv2-holder {
  width: 100%;
  height: calc(100vh - 150px);
  overflow: auto;
  background-color: var(--White);
  row-gap: 32px;
  display: flex;
  flex-direction: column;

  @media(max-width:992px) {
    height: auto;
  }

  .details_wrap {
    width: 100%;
  }

  .shieldstates {
    padding: 0 16px;

    .contentshield {
      width: 100%;

      .subnavbar-menu {
        width: 100%;
        padding: 0 16px;
        background: var(--bg-primary);
      }
    }
  }
}

.portfolio-select-row {
  padding-left: 65px;
}

div.ai-form-value-bt,
.ai-form-value-bt {
  border: 1px #666666;
  background: none;
  font-size: 20px;
  line-height: 30px;
  color: var(--Black);
  font-weight: 500;
  min-width: 100%;
  padding-right: 20px;

  &.line {
    border-bottom: 2px var(--Secondary) solid;
    padding-top: 4px;
    min-width: 0;
    padding-right: 0;

    .MuiSelect-select {
      font-size: 16px;
      color: var(--Primary);
      font-weight: bold;
      // padding-right: 0;
    }
  }

  .MuiSelect-select {
    padding: 0px;
  }

  fieldset {
    border: none;
  }
}

.ai-form-value-bt:focus {
  outline: none;
}

.ai-backtest-events .ai-tabs.nav-tabs .nav-item {
  color: var(--Navy-Blue);
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 700;
}

.ai-backtest-events .ai-tabs.nav-tabs .nav-item:after {
  background: var(--White);
}

.ai-backtest-events .ai-tabs.nav-tabs .nav-item.active:after {
  background: var(--Primary);
}

.ai-backtest-events .ai-tabs.nav-tabs .nav-item.active {
  color: var(--Primary);
}

.ai-form-group-bt {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.ai-date-selection-bt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin: auto;
}

.vl {
  border-left: 1px solid #666666;
  height: 60px;
  left: 50%;
  margin-left: 0px;
  top: 0;
}

/* .slick-list {
  padding: 0 32px;
} */
.ai-backtest-slider-bt {
  background-color: var(--White);
}

.ai-backtest-event-bt {
  background-color: #f1f1f1;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  padding: 16px 8px;
  display: flex !important;
  flex-direction: column;
  row-gap: 8px;
  color: var(--Primary);
}

.ai-backtest-event-bt p {
  color: var(--Navy-Blue);
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  margin: 0;
}

.ai-backtest-event-bt p.ai-event-timestamp-bt {
  font-weight: lighter;
  font-size: 12px;
  line-height: 18px;
  color: var(--Grey);
}

.ai-date-selection-bt {
  width: 100%;
}

.slick-track {
  display: flex;
  column-gap: 16px;
}

.slick-slider {
  padding: 24px 32px;
}

.testwindow-wrap {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.ai-backtest-container {
  background-color: var(--White);
  border-radius: 24px 24px 0 0;
  /* box-shadow: 0px -2px 30px rgb(0 0 0 / 10%); */
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.ai-backtest-container .ai-tabs.nav-tabs .nav-item {
  font-size: 16px;
  color: var(--Navy-Blue);
  font-weight: 700;
}

.ai-backtest-container .ai-tabs.nav-tabs .nav-item.active {
  color: var(--Primary);
}

.ai-backtest-container .ai-tabs.nav-tabs .nav-item:after {
  background: #fafafa;
}

.ai-backtest-container .ai-tabs.nav-tabs .nav-item.active:after {
  background: var(--Primary);
}

.risk-return-table {
  max-width: 80%; /* Adjust this value as needed */
  margin: auto; 
  border-collapse: collapse;
  text-align: center;
  margin-top: 10px;
  
  thead {
      th {
          font-family: Inter;
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          letter-spacing: 0px;
          background: white;
          color: #60034C;
          padding: 10px;
      }
  }

  tbody {
      td {
          font-family: Inter;
          font-size: 15px;
          font-weight: 600;
          line-height: 15px;
          letter-spacing: 0px;
          padding: 10px;

          &:first-child {
              color: #333333;
          }

          &:nth-child(2) {
              color: #800000;
              text-decoration: underline;
          }

          &:nth-child(3) {
              color: #FFA654;
              text-decoration: underline;
          }

          &:nth-child(4) {
              color: #54C1FF;
              text-decoration: underline;
          }

          &:last-child {
              color: #ED6663;
              text-decoration: underline;
          }
      }
  }
}

