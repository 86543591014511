.summary-header,
.summary-header-sm {
  margin: 12px 0;
  padding: 0;
  font-size: 20px;
  color: var(--Navy-Blue);
  font-family: "Inter";
  font-weight: 600;
  padding-bottom: 10px;
}

.summary-details-table table,
.summary-details-table-2 table,
.summary-details-table-3 table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.summary-details-table th,
.summary-details-table-2 th,
.summary-details-table-3 th {
  font-size: 14px;
  color: #60034c;
  text-align: left;
}

.summary-details-table td,
.summary-details-table-2 td,
.summary-details-table-3 td {
  font-size: 14px;
  color: #666666;
  padding-right: 22px;
  line-height: 22px;
  letter-spacing: -0.266px;
}

.summary-details-table td:nth-child(3),
.summary-details-table td:nth-child(6),
.summary-details-table-2 td:nth-child(3),
.summary-details-table-2 td:nth-child(6),
.summary-details-table-3 td:nth-child(3),
.summary-details-table-3 td:nth-child(6) {
  font-weight: 700;
}

.summary-TC,
.summary-TC-sm {
  /* width: 55%; */
  font-size: 14px;
  color: var(--Navy-Blue);
  text-align: left;
  padding: 24px;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 10px;
}

.summary-TC-sm {
  width: 100%;
}

.summary-TC p,
.summary-TC-sm p {
  margin: 0;
  line-height: 1.6;
}

.TC-text,
.TC-text-sm {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  align-items: center;
  text-align: justify;
  color: #666666;
  margin: 0 0 8px;
}

.TC-text-sm {
  width: 100%;
}

.TC-text p,
.TC-text-sm p {
  margin: 0;
  line-height: 1.6;
}

.accept-TC {
  margin: 24px 0;
}

.accept-TC-Text {
  font-size: 14px;
  color: var(--Navy-Blue);
  padding-left: 12px;
  margin: 0;
}

.checkout-buttonText {
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--White);
}

.summary-buttons_ar,
.summary-buttons_ar-md {
  width: 70%;
  margin: 0 0 24px 0;
  display: flex;
  /* justify-content: flex-end; */
}

.summary-buttons_ar-md {
  width: 100%;
}

.ai-shieldsummary {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 32px;
  border-radius: 10px;
  overflow: hidden;
}

.ai-shieldsummary>table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 6px 12px;
  table-layout: fixed;
  border-collapse: collapse;
}

.ai-shieldsummary h3 {
  display: block;
  background: #fbece6;
  color: var(--Primary);
  margin: 0;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px 8px 0px 0px;
}

.ai-shieldsummary>table>thead>tr>th {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--Primary);
  font-family: "Inter";
  font-weight: 600;
  white-space: nowrap;
}

.ai-shieldsummary>table tbody th {
  width: 5%;
  padding: 10px 0 0 0;
  font-weight: 600;
  font-size: 14px;
  color: #5b5b5b;
  text-align: center;
}

.ai-shieldsummary>table tbody td {
  width: 5%;
  padding: 10px 0 10px 0;
}

.ai-shieldsummary>table tbody::-webkit-scrollbar {
  width: 8px;
  background-color: var(--Peach);
  border-radius: 8px;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.54);
  margin-left: 8px;
}

.ai-shieldsummary>table tbody::-webkit-scrollbar-thumb {
  background-color: var(--Primary);
  border-radius: 8px;
}

/* .ai-shieldsummary > table tbody tr {
    display: block;
} */

.ai-shieldsummary>table tbody tr:nth-child(even) {
  background: #fafafa;
}

.ai-shieldsummary>table tbody tr:last-child {
  border-bottom: none;
}

.ai-shieldsummary>table tbody tr td {
  color: #5b5b5b;
  font-size: 14px;
}

.ai-shieldsummary>table>thead tr th,
.ai-shieldsummary>table tbody tr td {
  text-align: center;
}

.ai-shieldsummary>table>thead tr th:nth-child(1) {
  text-align: left;
  padding: 10px;
}

.ai-shieldsummary>table tbody tr td:nth-child(1),
.ai-shieldsummary>table tbody tr td:nth-child(2) {
  text-align: center;
}

.ai-shieldsummary>table tbody tr td:nth-child(1),
.ai-shieldsummary>table tbody tr td:nth-child(4) {
  color: #5b5b5b;
  font-weight: 600;
}

.summary-details-table {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
}

.checkout-details {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}

.checkout-details label {
  color: var(--Grey);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  min-width: 165px;
}

.checkout-details .col-sap {
  padding: 0 8px;
}

.checkout-details .value {
  font-weight: 700;
}

@media (max-width: 1420px) {
  .checkout-details .value {
    width: 100%;
  }

  .checkout-details .col-sap {
    display: none;
  }

  .checkout-details label {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .checkout-details {
    width: 100%;
  }
}