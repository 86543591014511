.bold {
    font-weight: 600;
}

.notice {
    width: 100%;
    display: block;
}

.infocard .balance {
    padding: 0;
}

.infocard .balance .available_balance {
    width: 100%;
    padding: 8px 0;
}

.table-body {
    max-width: 670px;
    width: 100%;
    margin: 20px auto;
    background: #fff;
    border: 1px solid #ddd;
    padding: 10px;
}

.table-1 {
    border: 1px solid #666666;
    width: 100%;
    border-radius: 3px;
    padding: 10px;
    overflow: hidden;
}

.tab-1 {
    display: flex;
    text-align: center;
    align-items: center;
}

.text-1 {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: #666666;
    font-weight: 600;
    margin: 4px 0;

}

.txt-bold {
    color: #333333;
    font-weight: 700;
}

.colon {
    color: #E58B76;
    padding: 0 8px;
}

.table-heading h2 {
    color: #3E4968;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 8px;
}

.t-center {
    text-align: center;
}

.table-inner2 {
    width: 100%;
    border: 1px solid #666666;
    border-radius: 3px;
    padding: 12px;
}

.table-column {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    flex-wrap: wrap;
}

.table-2text {
    flex: 1 1 250px;
    padding-inline-start: 0px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.tab-11 {
    width: 100%;
}

.text-11 {
    width: 60%;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
}

.tab-12 {
    width: 100%;
}

.text-12 {
    width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
}

.table-3 {
    width: 100%;
    border: 1px solid #666666;
    border-radius: 6px;
    margin-top: 12px;
    border-spacing: 0px;
    overflow: hidden;
}

.table-3 th {
    background: #FBECE6;
    padding: 8px;
}

.table-texts {
    color: #666666;
    padding: 8px;
}

.table-texts1 {
    text-align: right;
    color: #666666;
    padding: 8px;
}

.table-main-head {
    color: #60034C;
    font-weight: 600;
}

.table-main-head1 {
    text-align: right;
    color: #60034C;
    font-weight: 600;
}

th,
td {
    text-align: left;

}

.text-footer {
    text-align: right;
    padding: 8px;
}

.footer-col {
    color: #60034C;
    font-weight: 600;
}

.footer {
    background-color: #FBECE6;
    border-collapse: collapse;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.main-column:nth-child(odd) {
    background-color: #FAFAFA;
}

table.table-4 {
    margin-top: 25px;
}

.table-4 .small-heading {
    text-align: center;
    margin: 0;
}



.txt-bold1 {
    text-decoration: underline;
}

.txt-bold12 {
    text-decoration: none;
}

.table-5-head h5 {
    font-weight: 700;
    margin: 0 0 8px 0;
}

.para-5 {
    margin-bottom: 8px;
}

.basis-details,
.assign-menu {
    padding-inline-start: 23px;
    margin: 0;
}

.basic-details-list {
    list-style: upper-roman;
    margin-bottom: 8px;
}

.assign-lists {
    list-style: lower-alpha;
    margin-top: 8px;
}

.notice-lists {
    list-style: lower-roman;
    margin-top: 8px;
}
.notice-center{
    padding-left: 40px;
    text-decoration: none;
}

.txt-underline {
    text-decoration: underline;
    font-weight: bold;
}

.txt-underlinebold {
    font-weight: 500;
}

ul {
    padding-left: 40px;
}

.acceptterm {
    margin-top: 32px;
}

.t-signature {
    text-align: right;
}

.t-signature p {
    float: right;
}

.shield-text {
    overflow: auto;
}
.end-agreement{
    text-align: center;
    align-items: center;
}