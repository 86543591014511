.ClientList,
.ClientList-2 {
  width: 100%;
  border-collapse: collapse;
  display: inline-block;
  max-height: 400px;
  overflow: auto;
}

.ClientList-2 {
  border-spacing: 6px;
}

.ClientList::-webkit-scrollbar,
.ClientList-2::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.54);
  margin-left: 8px;
}

.ClientList::-webkit-scrollbar-thumb,
.ClientList-2::-webkit-scrollbar-thumb {
  border-radius: 8px;
}

.ClientList th {
  padding: 0 0 8px 0;
  color: #60034c;
  font-weight: 700;
  letter-spacing: 1px;
  border-bottom: 1px solid #999;
  width: 1px;
  white-space: nowrap;
}

.ClientList-2 th {
  padding: 0 8px 12px 8px;
  padding-bottom: 5px;
  color: #60034c;
  font-weight: 700;
  letter-spacing: 1px;
  border-bottom: 3px solid #f0eef0;
  min-width: 50px;
  white-space: nowrap;
}

.ClientList th .stck_table_heading,
.ClientList-2 th .stck_table_heading {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #60034c;
  text-transform: uppercase;
}

.ClientList th:nth-child(3),
.ClientList-2 th:nth-child(3) {
  text-align: left;
}

.ClientList th:nth-child(1),
.ClientList-2 th:nth-child(1),
.ClientList th:nth-child(2),
.ClientList-2 th:nth-child(2) {
  text-align: left;
}

.ClientList th:nth-child(4),
.ClientList-2 th:nth-child(4) {
  text-align: center;
}

.ClientList th:nth-child(5),
.ClientList-2 th:nth-child(5) {
  text-align: right;
}

.ClientList td {
  text-align: left;
  color: #333333;
  font-size: 14px;
  width: 5%;
  padding: 16px 0;
  text-transform: uppercase;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
}

.ClientList-2 td {
  text-align: left;
  color: #333333;
  font-size: 14px;
  width: 5%;
  padding: 10px 0 10px 0;
  text-transform: uppercase;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
}

.ClientList td:nth-child(3),
.ClientList-2 td:nth-child(3) {
  text-align: left;
}

.ClientList td:nth-child(1),
.ClientList-2 td:nth-child(1),
.ClientList td:nth-child(2),
.ClientList-2 td:nth-child(2) {
  text-align: left;
}

.ClientList td:nth-child(4),
.ClientList-2 td:nth-child(4) {
  text-align: center;
}

.ClientList td:nth-child(2),
.ClientList-2 td:nth-child(2) {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
}

.ClientList td:nth-child(5),
.ClientList-2 td:nth-child(5) {
  text-align: right;
}

.filter-inputs-min {
  border: 1px solid #666666;
  border-radius: var(--form-border-radius);
  background-color: var(--form-background-color);
  color: var(--Primary);
  font-size: var(--form-text-size);
  padding: 10px;
  margin: 12px 16px 0px 16px;
  box-sizing: border-box;
}

.filter-inputs-max {
  border: 1px solid #666666;
  border-radius: var(--form-border-radius);
  background-color: var(--form-background-color);
  color: var(--Primary);
  font-size: var(--form-text-size);
  padding: 10px;
  margin: 8px 16px 0px 16px;
  box-sizing: border-box;
}

.filter-btn {
  padding: 22px 48px;
  display: flex;
  flex-direction: row;
  align-self: center;
  font-size: 16px;
  border: none;
  line-height: 4px;
  border-radius: 40px;
  font-weight: 700;
  color: var(--White);
  width: fit-content;
  cursor: pointer;
  background: var(--Primary);
}

.filter-label {
  color: var(--Primary);
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  margin: 0px;
}

.error-message {
  margin-left: 16px;
  font-size: 10px;
  font-weight: 700;
  color: red;
}

.filter-paper {
  margin-top: 4px;
  margin-bottom: 16px;
  box-shadow: 0px 0px 0px (0, 0, 0, 0);
}

.chip {
  margin: 8px;
  font-size: 12px;
  font-weight: 700;
  color: #e58b76;
  text-transform: capitalize;
}

.link {
  text-decoration: underline;
}

.bar_wrapper {
  display: flex;
  flex-direction: column;
}

.search_bar {
  display: flex;
  column-gap: 24px;
}

.searchinput {
  display: flex;
  column-gap: 0;
  border-radius: 4px;
  background: #F1F1F1;
  padding-left: 8px;
  flex-direction: row;
  flex: 1;
}

.searchinput img {
  width: 24px;
}

.addclient_btn {
  padding: 22px 20px;
  align-self: center;
  font-size: 16px;
  border: none;
  line-height: 4px;
  border-radius: 40px;
  font-weight: 700;
  color: var(--White);
  width: fit-content;
  cursor: pointer;
  background: var(--Primary);
  margin-left: 25px;
}

.import_button_wrapper {
  display: flex;
  flex-direction: row;
  text-align: center;
}