.details_table {
  margin-left: 56px;
  padding: 0;
}

.details_table-2,
.details_table-3 {
  padding: 0 15px;
}

.details_table td {
  font-size: 24px;
}

.details_table-2 td {
  font-size: 24px;
}

.details_table-3 td {
  font-size: 18px;
}

.combinationMessage {
  font-size: 14px;
  font-weight: 500;
  color: #6f6f6f;
}

.portfolio-table-wrapper * {
  box-sizing: border-box;
}

.portfolio-table-container {
  background-color: var(--White);
  /* box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.1); */
}


.portfolio-table-actions {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portfolio-graph-wrapper {
  padding: 0 12px;
  margin: 24px 0;
}

@media (max-width: 640px) {
  .portfolio-table {
    padding: 0;
  }
}