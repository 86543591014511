.portsolution {
    h3 {
        width: 100%;
        margin: 0;
    }

    p {
        margin: 0;
    }

    .shieldcard.info {
        align-items: flex-start;
        padding: 0;
    }
}

.shieldcompare_page .shildcompare .shieldcard div.btncard {
    flex: 1;
    display: flex;
    justify-content: flex-start !important;
    flex-direction: column;
    align-items: flex-start;

    .ai-btn {
        align-self: flex-start;

    }
}

.shieldcompare_page.portsolution {
    .shildcompare {
        padding: 0 16px 16px;
        border-bottom: 1px #E0D1DC solid;
    }
}

.shieldstates {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 32px;
    column-gap: 48px;
    row-gap: 48px;
    align-items: flex-start;

    .contentshield {
        display: flex;
        width: 55%;
        position: relative;
        flex-direction: column;

        @media (max-width:1160px) {
            width: 100%;
        }

        .subnavbar-menu {
            position: static;
            padding: 0;

            a {
                display: flex;
                column-gap: 8px;
            }
        }
    }

    .table_holder {
        display: block;
        flex: auto;
        border: 1px #efefef solid;
        border-radius: 0 8px 8px 8px;
        margin-top: 8px;

        .table_height {
            max-height: 400px;
        }

        .tableheading {
            color: var(--Primary);
            background: var(--bg-primary);
            padding: 16px;
            font-weight: bold;
        }

        tfoot {
            background: var(--bg-primary);

            td {
                font-size: 14px;
                font-weight: bold;
                color: var(--Primary);

                &:first-child {
                    color: var(--Navy-Blue);
                }
            }
        }
    }

    .solutiontrack {
        display: flex;
        flex-direction: column;
        // row-gap: 32px;
        width: calc(45% - 48px);

        @media (max-width:1160px) {
            width: 100%;
        }

        .porfoliovalues {
            width: 140px;
            font-size: 12px;
            font-weight: bold;

            div {
                padding-right: 10px;
            }
        }

        .ProjectionApp {
            padding-left: 80px;

            .port_val,
            .port_date {
                span {
                    font-size: 12px;
                }
            }

            .port_val {
                span {
                    left: -57px;
                    width: 134px;
                }
            }


            .porfoliovaluesline {

                &:before,
                &:after {
                    width: calc(100% - 170px);
                    left: 140px;
                }
            }

            .port_date {
                width: calc(100% - 170px);
                left: 140px;
            }
        }

        .trackstates {
            display: flex;
            padding: 16px;
            flex-direction: column;
            background: var(--bg-secondary);
            border-radius: 8px;
            row-gap: 16px;

            .trackrisk {
                display: flex;
                align-items: center;

                span {
                    display: block;
                    color: var(--Secondary);
                    font-weight: bold;
                }
            }

            h3 {
                color: var(--Primary);
                font-weight: bold;
                font-size: 14px;
                text-transform: uppercase;
                display: flex;
                column-gap: 8px;
            }

            .statesview {
                display: flex;
                justify-content: space-between;

                .portfolio-bars {
                    flex-direction: column;
                    row-gap: 8px;

                    .portfolio-risk-bar {
                        display: flex;
                        align-items: center;
                        column-gap: 8px;
                        flex-direction: row-reverse;
                    }
                }
            }

            @media (max-width:1160px) {
                flex: auto;
            }
        }
    }
}