.shield-protect-holder-lg {
  /* width: 450px; */
  height: 457px;
  margin: auto;
  padding: 25px 100px 28px;
  /* border-radius: 16px;   */
}

.shield-protect-holder-md {
  height: 457px;
  margin: auto;
  padding: 25px 100px 28px;
  /* border-radius: 16px; */
}

.shield-protect-holder-sm {
  width: 400px;
  height: 457px;
  padding: 25px 39px 28px;
  border-radius: 16px;
  margin: auto;
}

.shield-protect-holder-xs {
  width: 300px;
  height: 457px;
  margin: auto;
  border-radius: 16px;
}

.shield-protect-content-lg {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.shield-protect-content-xs {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.buy-shield-button {
  width: 130px;
  height: 15px;
  padding: 22px 42px;
  border-radius: 32px;
  background-color: #60034c;
}

.buy-shield-button-xs {
  width: 70px;
  height: 15px;
  padding: 22px 42px;
  border-radius: 32px;
  background-color: #60034c;
}

.shield-protect-button-text-lg {
  width: 121px;
  height: 20px;

  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--White);
}

.shield-protect-button-text-xs {
  width: 70px;

  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--White);
}

.shield-protect-header-text-lg {
  font-size: 22px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #263154;
}

.shield-protect-header-text-xs {
  padding-top: 30px;

  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #263154;
}

.shield-protect-subheader-text {
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #60034c;
}

.shield-protect-total-text {
  margin: 0 0 15px 0px;

  font-size: 25px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #263154;
}

.shield-protect-total-text-xs {
  margin: 0 0 0px 15px;
  padding-bottom: 10px;

  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #263154;
}

.shield-protect-value-text {
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #263154;
}

.shield-diy-value-text {
  font-size: 15px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #8e8e8e;
  margin: auto;
}

.space_between_button-lg {
  padding-top: 100px;
}

.ai-shield-calculator-container {
  background-color: var(--White);
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  column-gap: 24px;
  row-gap: 24px;
}

.ai-shield-calculator-container * {
  box-sizing: border-box;
}

.ai-shield-calculator-container .ai-shield-calculator-select select {
  appearance: none;
  border: none;
  padding: 0 28px 4px 0;
  font-size: 20px;
  color: var(--Secondary);
  position: relative;
  border-radius: 0;
  border-bottom: 2px solid var(--Secondary);
  background: var(--White);
  width: 100%;
  font-weight: 700;
  margin: 8px 0;
}

.ai-shield-calculator-container .ai-shield-calculator-select select:focus {
  outline: none;
}

.ai-shield-calculator-container .ai-shield-calculator-select select option {
  font-size: 16px;
  color: var(--Navy-Blue);
}

.ai-shield-calculator-container .ai-shield-calculator-select .ai-shield-calculator-select-arrow {
  position: absolute;
  top: 30px;
  right: 0px;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("../../images/arrowdown.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.ai-calc-plan-details .ai-stocks label {
  display: block;
  font-size: 14px;
}

.ai-calc-plan-price {
  margin: 0px 0 0 0;
  font-size: 28px;
  color: #263154;
  font-weight: 700;
  flex: 1;
  min-width: 120px;
}

.ai-calc-plan-price p {
  margin: 0px 0 0 0;
  font-size: 20px;
  color: #263154;
  font-weight: 500;
  flex: 1;
  min-width: 120px;
}

.infocard label {
  margin: 0 !important;
}

.ai-calc-plan-details .ai-stocks text {
  margin-top: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
}

.ai-calc-plan-details .ai-stocks p span {
  display: inline-block;
  margin-right: 4px;
}

.ai-shield-calculator-container .ai-shield-on-action {
  flex-direction: row;
  text-align: center;
}

.ai-shield-calculator-container .ai-shield-on-btn .ai-img {
  margin-right: 10px;
}

.shield-row {
  display: flex;
  column-gap: 48px;
  row-gap: 24px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.ai-shield-calculator-select {
  display: flex;
  flex-direction: column;
  width: 175px;
  position: relative;
}

@media (max-width: 1116px) {
  .ai-calc-plan-details .ai-stocks p {
    font-size: 24px;
  }
}

@media (max-width: 768px) {

  .ai-guaranteed-table .ai-card-header h4,
  .ai-affordable-table .ai-card-header h4 {
    font-size: 18px;
  }

  .ai-shield-calculator-container {
    padding: 30px 15px;
  }

  .ai-guaranteed-results .ai-helpText,
  .ai-affordable-results .ai-helpText {
    font-size: 28px;
  }
}

@media (max-width: 1400px) {
  .shield-row {
    column-gap: 32px;
  }
}

@media (max-width: 840px) {
  .shield-row .infocard {
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
  }

  .ai-calc-plan-details {
    width: 100%;
  }

  .ai-shield-calculator-container .new_row {
    flex-direction: column;
    align-items: center;
  }

  .analyze-portfolio-column1,
  .analyze-portfolio-column2 {
    width: 100%;
  }

  .ProjectionApp {
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .shield-row .infocard {
    width: 100%;
  }
}

.ai-shield-calc-cone {
  width: 50%;
}

.infocard .ai-percent-value p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  height: 20px;
  font-weight: 700;
}

.hr-line {
  width: 100%;
  opacity: 0.2;
  border-top: solid 1px #666666;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shield-row.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: 24px;
}
.buttonbuy{
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  flex-direction: column;
}
.buttonbuy button{
  width: 100%;
}
/* .portfolio-table{
  padding: 0;
} */