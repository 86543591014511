.cs-title{
    width: 100%;
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: #5A5656;
}

.cs-subtitle{
    width: 100%;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.emptyfill{
    background-color:#EDDCD6;
    width: 100%;
    height: 325px;
    left: 94px;
    top: 273px;
}

.emptyfill1{
    height: 147.57px;
    left: 815px;
    top: 273px;
    background: #EDDCD6;
}

.emptyfill2{
    height: 162px;
    left: 815px;
    top: 450.43px;
    background: #EDDCD6;
}