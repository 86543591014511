.analyze-portfolios {
  width: 100%;
  border-radius: 24px;
  background-color: var(--White);
}

.analyze-portfolio-text-pos {
  padding: 10px 70px 0;
  margin-left: 25px;
  margin-right: 25px;
}

.analyze-portfolio-text {
  font-family: InterV_Medium;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #263154;
}

.analyze-portfolio-options {
  /* margin-top: 30px; */
}
