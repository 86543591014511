.subheader {
  display: flex;
  justify-content: space-between;
  height: 140px;
  padding: 40px 0 0;
}

.myPortfolio {
  margin-left: 40px;
  font-family: InterV_Medium;
  font-size: 42.6px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.99;
  letter-spacing: -0.27px;
  color: #263154;
}

.upload_area {
  background-color: var(--White);
  padding: 32px;
  /* box-shadow: 0px -2px 30px rgb(0 0 0 / 10%); */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-direction: column;
  row-gap: 48px;
}

.message_upload h2 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: var(--Navy-Blue);
  margin: 0;
  line-height: 28px;
}

.didYouKnow {
  max-width: 405px;
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  border: dashed 1px #afa2b5;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.didYouKnow h3 {
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  font-weight: 700;
  color: var(--Primary);
}

.didYouKnow p {
  color: var(--Navy-Blue);
  margin: 0;
  font-size: 16px;
  line-height: 28px;
}

@media (max-width: 992px) {
  .upload_area {
    padding: 24px;
  }
}

@media (max-width: 768px) {
  .upload_area {
    padding: 24px 8px;
  }
}