.LineItemChangesTableBody {
  width: 95%;
  margin: auto;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  height: 300px;
  background: white;
  margin-top: 20px;
  flex-wrap: wrap;
}

.LineItemChangesWindowHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #fbece6;
  border-radius: 10px 10px 0px 0px;
  height: 50px;
}

.WindowHeaderTextBlock {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}

.WindowHeaderLabels {
  font-family: Inter;
  color: #60034c;
  font-weight: 600;
  font-size: 16px;
}

.WindowText {
  font-family: Inter;
  color: var(--Navy-Blue);
  font-weight: 600;
  text-transform: uppercase;
}

.VerticalSeparator {
  border-left: 1px solidvar(--Navy-Blue);
  height: 40%;
  margin-left: 20px;
}

.lineItemChangesTableHolder {
  width: 95%;
  margin: auto;
  overflow-y: scroll;
  height: 250px;

}

.lineItemChangesTable {
  width: 100%;
  border-collapse: collapse;
}

.lineItemChangesTable thead {
  border-bottom: 1px solid #f2f2f2;
  height: 40px;
  text-align: left;
}

.lineItemChangesTable::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.54);
  margin-left: 8px;
}

.lineItemChangesTable::-webkit-scrollbar-thumb {
  border-radius: 8px;
}

.lineItemChangesTable tr {
  text-align: left;
  height: 40px;
  text-transform: uppercase;
}

.lineItemChangesTable th {
  color: #60034C;
  font-weight: 700;
  letter-spacing: 1px;
  white-space: nowrap;
}

.lineItemChangesTable td {
  padding: 0 0 0 0;
  color: var(--Navy-Blue);
  font-weight: 500;
  letter-spacing: 1px;
  white-space: nowrap;
}

.lineItemChangesTable tr:nth-child(even) {
  background-color: #fafafa;
}