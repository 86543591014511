.advisor-holder {
  height: 100%;
  background: #fff;
  overflow: auto;
}

.advisor-details-holder {
  display: flex;
  padding: 24px 32px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
}

.advisor-details-holder .infocard {
  flex-direction: row;
  width: calc(25% - 24px);
  background: #f1f1f1;
  padding: 24px;
  border-radius: 4px;
  column-gap: 24px;
}

.advisor-details-holder .infocard img {
  max-width: 60px;
}

.advisor-details-holder .infocard p {
  width: 100%;
  font-size: 32px;
}

.stats-holder {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(24, 39, 75, 0.15);
  border-radius: 8px;
}

.details_wrap {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
}

.subdetails-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  column-gap: 8px;
  width: 100%;
}

.subdetails-wrap h3 {
  margin: 0;
}

.subdetails-wrap p {
  font-size: 35px;
  line-height: 60px;
  color: var(--Secondary);
  font-weight: 600;
  margin: 0;
}

.risk-details-holder {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 24px 32px;
  justify-content: flex-start;
  column-gap: 30px;
  margin: auto;
}

.risk-details-holder p {
  margin: 0;
}

.market-risk-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  row-gap: 16px;
  min-width: 130px;
  background: #f1f1f1;
}

.market-risk-holder p {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: var(--Navy-Blue);
}

.risk-progress-holder {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 50px;
  flex: 1;
  min-height: 200px;
}

.advisor-market-risk-value p {
  transform: rotate(-90deg);
  color: #fff;
}

.advisor-market-risk {
  border-radius: 50px;
  background: var(--White);
  position: relative;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  flex: 1;
}

.advisor-market-risk-value {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: 1s ease 0.3s;
  border-radius: 0 0 25px 25px;
}

.GraphArea {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.GraphTab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 16px;
  row-gap: 16px;
  width: 100%;
  flex-wrap: wrap;
}

.GraphTab .ai-form-value {
  max-width: 150px;
}

.per_up_tab {
  display: flex;
  flex-direction: row;
  height: 35px;
  box-shadow: 0px 4px 4px rgba(24, 39, 75, 0.15);
  align-items: center;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.percentage_text {
  color: #38bb55;
  padding-right: 5px;
  font-family: "Inter";
  font-weight: 600;
}

.desc_text {
  font-family: "Inter";
  color: var(--Navy-Blue);
  font-weight: 600;
}

.key_1 {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #60034C;
  margin-right: 7px;
}

.Legend_first {
  display: flex;
  flex-direction: row;
  height: 35px;
  align-items: center;
}

.key_2 {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #E58B76;
  margin-right: 7px;
}

.legendBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 20px;
  flex-wrap: wrap;
}

.period_picker_advisor {
  height: 35px;
  font-family: "Inter";
  font-weight: 600;
  padding-right: 10px;
  padding-left: 20px;
  border-radius: 17.5px;
  color: var(--Navy-Blue);
}

.GraphHolder {
  margin-top: 20px;
}

@media (max-width: 1300px) {
  .advisor-details-holder .infocard {
    width: calc(50% - 8px);
  }

  .advisor-details-holder {
    row-gap: 16px;
    column-gap: 16px;
    padding: 16px;
  }

  .risk-details-holder {
    padding: 16px;
  }
}

@media (max-width: 992px) {
  .risk-details-holder {
    flex-direction: column;
    row-gap: 24px;
  }

  .advisor-holder {
    height: auto;
  }
}

@media (max-width: 768px) {

  .advisor-details-holder .infocard {
    width: 100%;
  }

  .advisor-details-holder .infocard h3,
  .advisor-details-holder .infocard p {
    text-align: left;
  }

  .advisor-details-holder .infocard h3 {
    margin-bottom: 8px;
  }
}