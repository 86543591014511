.main-lg {
  background-color: var(--White);
  padding: 32px;
  /* box-shadow: 0px -2px 30px rgb(0 0 0 / 10%); */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1;
  flex-direction: column;
  grid-row-gap: 48px;
  row-gap: 48px;
}

.main-sm {
  position: relative;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  background-color: var(--White);
  padding: 32px;
  width: 100%;
  height: calc(100vh - 187px);
  box-sizing: border-box;
}

.main-sm {
  height: calc(100vh - 175px);
  justify-content: center;
  align-items: center;
}

.portfolioform {
  background-color: var(--White);
  padding: 32px;
  box-shadow: 0px -2px 30px rgb(0 0 0 / 10%);
  border-radius: 4px;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin: 0 !important;
  justify-content: center;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 8px;
  position: relative;
}

.form-label-lg {
  text-transform: uppercase;
  color: var(--Primary);
  display: block;
  font-family: "Inter";
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 13px;
}

.input-type-text-lg {
  outline: 0;
  border-radius: 5px;
  border: 1px dashed #666666;
  background-color: white;
  font-size: 16px;
  padding: 16px;
}

.input-type-text-area-lg {
  border-radius: 5px;
  border: 1px dashed #666666;
  width: 100%;
  height: 190px;
  background-color: white;
  resize: none;
  font-size: 16px;
  padding: 16px;
}

.input-type-text-area-md {
  width: 100%;
  height: 190px;
  border-radius: 5px;
  border: 1px dashed;
  background-color: white;
  resize: none;
  font-size: 16px;
  padding: 18px 25px 25px 18px;
}

.input-type-text-area-sm {
  margin-top: 18px;
  width: 391px;
  height: 190px;
  border-radius: 5px;
  border: 1px dashed #666666;
  background-color: white;
  resize: none;
  font-size: 16px;
  padding: 18px 25px 25px 18px;
}

textarea:focus {
  outline: 1px solid #979797;
}

.invalidInputMessage {
  font-size: 14px;
  color: #ff0000;
  margin-top: 0;
}

.button-div {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
}

.required:after {
  content: " *";
  color: red;
}

.portfolioform .ai-form-value {
  margin-top: 0;
}