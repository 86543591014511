:root {
  --Peach: #fbece6;
  --Navy-Blue: #3e4968;
  --Primary: #60034c;
  --White: #ffffff;
  --Secondary: #e58b76;
  --Black: #000;
  --Grey: #666666;
  --Green: #38BB55;
  --Orange: #FFA654;
  --Error: #ed6663;
  --Blue: #54C1FF;
  --bg-gray: #f5f5f5;
  --bg-primary: #EFE6ED;
  --bg-secondary: #FCF3F1;

  --form-label-size: 12px;
  --form-text-size: 14px;
  --form-border-radius: 7.5px;
  --button-border-radius: 32px;
}

$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;

@import "../src/styles/bootstrap";

body {
  background-color: var(--Peach);
  margin: 0;
  padding: 0;
  font-weight: 400;
}

iframe
{
    display: none;
}

.p16 {
  padding: 16px;
}

.br-white {
  background: var(--White);
}

h1,
.h1 {
  font-weight: 700;
  font-size: 42px;
}

h2,
.h2 {
  font-weight: 700;
  font-size: 30px;
}

h3,
.h3 {
  font-weight: 700;
  font-size: 24px;
}

h4,
.h4 {
  font-weight: 700;
  font-size: 18px;
}

.ai-full-height {
  height: 100vh;
}

img {
  max-width: 100%;
}

.ai-primary-text {
  color: var(--Primary);
}

.react-tooltip-lite {
  .ai-link {
    color: var(--Primary);

    &:hover {
      color: var(--Primary);
    }
  }
}

.ai-link {


  text-decoration: none;
  color: var(--Primary);
  font-size: 14px;

  font-weight: 700;
}

.ai-link-secondary {
  text-decoration: underline;
  color: var(--Secondary);
  font-size: 14px;
  font-weight: 700;
}

.ai-btn[disabled],
.ai-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.ai-btn.ai-btn-delete {
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid #e58b76;
  color: #e58b76;
}

.ai-slider .slick-dots {
  bottom: -70px;
}

.ai-slider .slick-dots li button:before {
  color: var(--White);
  font-size: 16px;
}

.ai-slider .slick-dots li.slick-active button:before {
  color: var(--White);
}

.ai-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 12px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      padding-right: 30px;

      &:last-child {
        padding-right: 0;
      }

      a {
        text-decoration: none;
        font-size: 13px;
      }
    }
  }
}

.ai-error-message {
  p {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    // padding: 4px 0;
    color: var(--Error);
    line-height: 18px;
  }
}

// .ai-error-message:after {
//   content: "!";
//   display: block !important;
//   width: 20px;
//   height: 20px;
//   color: #fff;
//   background: #555;
//   text-align: center;
//   line-height: 20px;
//   font-size: 14px;
//   border-radius: 10px;
// }

.ai-main-wrapper {
  background-color: var(--White);
  border-radius: 24px 24px 0 0;
  padding: 60px;
  flex: 1;
  box-sizing: border-box;
  box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.1);
}

.ai-content-wrapper {
  width: 560px;
  max-width: 100%;
  background: rgba(229, 139, 118, 0.1);
  border-radius: 16px;
  padding: 32px;

  .ai-shield-value {
    margin-bottom: 60px;
  }
}

.ai-row {
  display: flex;
  width: 100%;
  column-gap: 20px;
}

.ai-form-value {
  background: #F1F1F1;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  color: var(--Black);
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.ai-form-value-mui {
  border: none !important;

  .MuiSelect-select {
    background: #F1F1F1;
    padding: 12px;
    border-radius: 4px;
    border: none;
    color: var(--Black);
    width: 100%;
    box-sizing: border-box;
    margin: 0 !important;
    height: 48px;

    >span {
      height: 16px;
      display: inline-block;
    }

    &:focus {
      outline: none;
    }
  }

  fieldset {
    border: none !important;
  }
}

.ai-form-group {
  margin-bottom: 12px;
  display: flex;
  width: 100%;

  .ai-form-label {
    text-transform: uppercase;
    font-size: var(--form-label-size);
    color: var(--form-label-color);
    display: block;

    font-weight: 700;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 10px;
  }

  textarea {
    min-height: 100px;
  }

  .ai-form-value-mui {
    margin-top: 10px;
  }

  .ai-form-value {
    background: #F1F1F1;
    margin-top: 10px;
    padding: 16px;
    border-radius: 4px;
    border: none;
    color: var(--Black);
    width: 100%;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    .ai-form-wrapper .ai-button-row {
      margin-top: 40px;
    }

    .ai-form-wrapper .ai-button-row {
      margin-top: 40px;
    }
  }

  .ai-datepicker {
    border: 1px solid var(--Primary);
    border-radius: var(--form-border-radius);
    background-color: var(--form-background-color);
    color: var(--Navy-Blue);
    font-size: var(--form-text-size);
    margin-top: 10px;
    padding: 18px 20px;
    width: 100%;
    box-sizing: border-box;
  }
}

.ai-otp-group {
  margin-bottom: 12px;
  // display: flex;
  // width: 100%;

  .ai-form-label {
    text-transform: uppercase;
    font-size: var(--form-label-size);
    color: var(--form-label-color);
    display: block;

    font-weight: 700;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 10px;
  }

  textarea {
    min-height: 100px;
  }

  .ai-form-value-mui {
    margin-top: 10px;
  }

  .ai-form-value {
    background: #F1F1F1;
    margin-top: 10px;
    padding: 16px;
    border-radius: 4px;
    border: none;
    color: var(--Black);
    width: 100%;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    .ai-form-wrapper .ai-button-row {
      margin-top: 40px;
    }

    .ai-form-wrapper .ai-button-row {
      margin-top: 40px;
    }
  }

  .ai-datepicker {
    border: 1px solid var(--Primary);
    border-radius: var(--form-border-radius);
    background-color: var(--form-background-color);
    color: var(--Navy-Blue);
    font-size: var(--form-text-size);
    margin-top: 10px;
    padding: 18px 20px;
    width: 100%;
    box-sizing: border-box;
  }
}

/* Sign up */

.ai-v-center {
  display: flex;
  align-items: center;
}

.ova {
  overflow: auto;
}

.flex1 {
  flex: 1;
}

.ai-container .ai-login-container .ia-footer {
  padding: 16px;
}

.ia-footer h2 {
  margin: 0 0 10px 0;
  font-size: 14px;
  color: var(--Primary);
}

.ia-footer p {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 4px 0;
}

.ia-footer .ia-footer-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ia-footer .ia-footer-links li {
  display: inline-block;
  padding: 0 12px 0 0;
  position: relative;
}

.ia-footer .ia-footer-links li:after {
  content: "";
  position: absolute;
  top: 0;
  right: 3px;
  height: 100%;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.54);
}

.ia-footer .ia-footer-links li a {
  text-decoration: none;
  color: var(--Primary);
  font-size: 14px;
  font-weight: 600;
}

.ia-footer .ia-footer-links li:last-child:after {
  display: none;
}

.ai-tabs.nav-tabs {
  list-style: none;
  margin: 0;
  border: none;
  justify-content: flex-start;
  padding: 0 32px;
  column-gap: 48px;
}

.ai-tabs li {
  display: inline-block;
  margin-right: 12px;
  position: relative;
}

.ai-tabs.nav-tabs .nav-item {
  position: relative;
  margin-right: 12px;
}

.ai-tabs.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.ai-tabs.nav-tabs .nav-item button,
.ai-tabs.nav-tabs .nav-item {
  appearance: none;
  background: transparent;
  border: none;
  color: var(--Grey);
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 8px 0;
  margin: 0;
  margin-bottom: 6px;

  &.active {
    color: var(--Primary);
  }
}

.ai-tabs li:last-child {
  margin-right: 0;
}

.ai-tabs.nav-tabs .nav-item button:after,
.ai-tabs.nav-tabs .nav-item:after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 0;
  height: 3px;
  width: 100%;
  border-radius: 3px;
  background-color: none;
}

.ai-tabs.nav-tabs .nav-item button.active:after,
.ai-tabs.nav-tabs .nav-item.active:after {
  background-color: var(--Primary);
}

.ai-container {
  overflow: hidden;

  .nav-tabs {
    border-bottom: none;

    .nav-item {
      button {
        cursor: pointer;
      }
    }
  }
}

.saprator {
  background: var(--Primary);
  width: 1px;
  opacity: 0.2;
}

.flexfull {
  display: flex;
  flex: 1;
}

.infocard-wrap {
  display: flex;
  column-gap: 32px;

  @media screen and (max-width: 680px) {
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
  }
}

.ai-column {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  align-content: center;
  align-items: center;
}

.ai-align-left {
  justify-content: flex-start;
  align-content: flex-start;
}

.ai-align-center {
  text-align: center;
}

.ai-align-right {
  justify-content: flex-end;
  align-content: flex-end;
}

.v-middle {
  align-items: center;
  justify-content: center;
}

.details_wrap {
  align-items: center;
  padding: 0 24px 8px;

  &.alon {
    float: right;
    justify-content: flex-end;
    margin-top: -78px;
    padding: 24px 32px 16px;
  }
}

.stats-holder .details_wrap {
  padding: 8px;
  grid-column-gap: 0;
  column-gap: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.stats-holder .details_wrap .infocard {
  padding: 16px;
  text-align: center;
  max-width: 300px;
  width: 100%;
}

.stats-holder .details_wrap .infocard p {
  width: 100%;
  text-align: left;
}

.stats-holder .details_wrap .infocard h3 {
  text-align: left;
}

.button_wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
}

.infocard {
  display: flex;
  flex-direction: column;

  h3,
  label {
    text-transform: uppercase;
    color: var(--Primary);
    font-size: 14px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 8px;

    @media screen and (max-width: $breakpoint-tablet) {
      margin: 0;
    }

    @media screen and (max-width: 680px) {
      min-width: 90px;
    }
  }

  p {
    font-size: 20px;
    line-height: 30px;
    color: var(--Black);
    font-weight: 500;
    margin: 0;

    @media screen and (max-width: $breakpoint-tablet) {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 680px) {
    flex-direction: row;
    align-items: center;
    column-gap: 24px;
  }
}

.ai_input {
  background: #F1F1F1;
  border: none;
  border-radius: 4px;
  height: 36px;
  padding: 8px 16px;
  box-sizing: border-box;

  &:focus {
    border: none;
    outline: none;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }
}

.floathead {
  display: flex;
  z-index: 2;
  position: relative;

  &.alon {
    width: 30%;
  }

  &.big {
    width: 50%;
  }

  .port_detail {
    padding-top: 16px;
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .details_wrap {
      align-items: flex-end;
      display: flex;
      row-gap: 16px;
      flex-direction: column;
    }
  }
}

// Tooltip

/* tooltip styles */
.react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite a {
  color: #86b0f4;
  text-decoration: none;
}

.react-tooltip-lite a:hover {
  color: #4286f4;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

/* overrides with a custom class */
.customTip .react-tooltip-lite {
  border: 1px solid #888;
  background: #ccc;
  color: black;
}

.customTip .react-tooltip-lite-arrow {
  border-color: #444;
  position: relative;
}

.customTip .react-tooltip-lite-arrow::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  z-index: 99;
  display: block;
}

.customTip .react-tooltip-lite-up-arrow::before {
  border-top: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  top: -11px;
}

.customTip .react-tooltip-lite-down-arrow::before {
  border-bottom: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  bottom: -11px;
}

.customTip .react-tooltip-lite-right-arrow::before {
  border-right: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -11px;
  top: -10px;
}

.customTip .react-tooltip-lite-left-arrow::before {
  border-left: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -11px;
  top: -10px;
}

.imageWrapper {
  margin: 50px 0 0;
  position: relative;
}

.imageWrapper img {
  width: 500px;
  height: 500px;
}

.controlled-example {
  max-width: 250px;
}

.controlled-example_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
}

.controlled-example_close-button {
  cursor: pointer;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  padding: 0;
}

.controlled-example_close-button:hover {
  color: grey;
}

.internal-scroll-container {
  height: 200px;
  overflow: auto;
}

.internal-scroll-container>div {
  padding-top: 100px;
  height: 400px;
}

.arrow-content-tooltip .react-tooltip-lite {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.arrow-content-tooltip .react-tooltip-lite-down-arrow svg {
  transform: translateY(1px);
}

.arrow-content-tooltip .react-tooltip-lite-right-arrow svg {
  transform: rotate(270deg) translateY(-4px) translateX(-4px);
}

.arrow-content-tooltip .react-tooltip-lite-up-arrow svg {
  transform: rotate(180deg) translateY(1px);
}

.arrow-content-tooltip .react-tooltip-lite-left-arrow svg {
  transform: rotate(90deg) translateY(5px) translateX(4px);
}

.c-secondary {
  color: var(--Secondary);
}

// Table
.MuiTable-stickyHeader {
  th {
    color: var(--Primary);
    text-transform: uppercase;
    font-weight: 600;
    white-space: nowrap;
  }

  th {
    .MuiIconButton-root {
      padding: 12px 0 12px 10px;
    }
  }

  .link {
    color: var(--Primary);
    font-weight: 600;
  }

  .c-green {
    color: var(--Green);
  }

  .c-orange {
    color: var(--Orange);
  }

  .c-error {
    color: var(--Error);
  }
}

// Button css

.ai-btn {
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
  border: none;
  line-height: 20px;
  border-radius: 4px;
  font-weight: 500;
  color: var(--White);
  width: fit-content;
  cursor: pointer;
  box-sizing: border-box;
  align-self: center;
  border: 1px solid;
  white-space: nowrap;
  justify-content: center;

  &.round {
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    padding: 0;
    align-items: center;
    justify-content: center;

    &.primary {
      background: var(--Primary);
      border-color: var(--Primary);
      color: var(--White);
    }

    &.secondary {
      background: var(--Secondary);
      border-color: var(--Secondary);
      color: var(--White);
    }

    &.small {
      width: 36px;
      height: 36px;
      min-width: 36px;
      min-height: 36px;
      box-sizing: border-box;
      font-size: 20px;
    }
  }

  &.small {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 14px;

    img {
      max-width: 14px;
    }
  }

  &.ex-small {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 12px;

    img {
      max-width: 12px;
    }
  }

  &.line {

    background: none;

    &.primary {
      border-color: var(--Primary);
      color: var(--Primary);
    }

    &.secondary {
      border-color: var(--Secondary);
      color: var(--Secondary);
    }
  }

  &.plain {
    border: none;
    padding: 0;
    background: none;
  }

  &.solid {
    &.primary {
      background: var(--Primary);
      border-color: var(--Primary);
      color: var(--White);
    }

    &.secondary {
      background: var(--Secondary);
      border-color: var(--Secondary);
      color: var(--White);
    }
  }

  img,
  svg {
    display: inline-block;
    vertical-align: middle;
    max-height: 16px;
  }
}

.listbtn {
  display: flex;
  column-gap: 16px;
  flex: 1;
  justify-content: flex-end;
}

.porttop {
  display: flex;
  padding-bottom: 16px;
  flex-wrap: wrap;
  row-gap: 16px;
}

.dropdown {
  position: relative;
  z-index: 2;

  &:hover .dropoption {
    display: block;
  }

  .dropoption {
    padding: 8px 16px;
    position: absolute;
    top: 100%;
    right: 0;
    display: none;
    background: var(--White);
    box-shadow: 0 8px 28px rgba(24, 39, 75, 0.12), 0 18px 88px rgba(24, 39, 75, 0.14);
    list-style: none;
    width: 250px;
    margin: 0;
    border-radius: 4px;

    li {
      padding: 14px 0;
      border-bottom: 1px rgba(14, 27, 66, 0.07) solid;
      white-space: nowrap;

      &:last-child {
        border-bottom: none;
      }

      button {
        background: none;
        width: 100%;
        border: none;
        display: flex;
        align-content: center;
        align-items: center;
        column-gap: 24px;
        cursor: pointer;
      }
    }
  }
}


#root,
.ai-content-part,
#root>div {
  height: 100vh;
}

.ai-content-part {
  display: flex;
  flex-direction: column;
}

.GraphHolder svg>g {
  transform: translate(0, 50px);
  width: 100%;
}

// tooltip

.react-tooltip-lite {
  background: var(--White);
  color: var(--Black);
  border: 1px var(--Secondary) solid;
  border-radius: 4px;
}

.react-tooltip-lite-arrow {
  border-color: var(--Secondary);
  // border-left: 6px solid transparent !important;
  // border-right: 6px solid transparent !important;
  // border-top-width: 6px !important;
}

.portfolio-table-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: var(--White);
  padding: 24px 32px;
  flex: 1;
  overflow: auto;
  flex-direction: column;

  >div {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    &.nav-wrap-csv {
      flex: initial;
      height: auto;
    }
  }
}

.table_holder {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  z-index: 1;
}

.table_height {
  flex: 1;
  overflow: auto;
  max-height: 100%;
}

.dib {
  display: inline-block;
}

.subnavbar-menu.navtab {
  padding: 16px;
}

.subnavbar-menu {
  &.fixed {
    height: 60px;
    column-gap: 32px;

    .SUBITEM-selected,
    .SUBITEM {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.advisor .subnavbar-menu.navtab {
  position: static;
}

.table_height tbody tr:nth-child(2n) {
  background: #fafafa;
}

.table_height tbody td {
  border: none !important;
  border-right: 1px #efefef solid !important;
  padding: 8px 16px;
}

.popoutbox {
  max-width: 200px;
  margin: 0;
  padding: 16px;
  row-gap: 8px;
  justify-content: center;

  p {
    width: 100%;
    margin: 0;
  }

  .ai-form-value {
    margin: 0;
  }
}

@media (max-width: 1366px) {
  .details_wrap {
    padding: 0 24px 16px 48px;
  }
}

@media (max-width: 1280px) {
  .details_wrap {
    flex-direction: column;
    row-gap: 16px;
    align-items: flex-start;
  }

  .details_wrap.alon {
    align-items: flex-end;
  }
}

@media (max-width: 992px) {
  .details_wrap {
    padding: 0 16px 16px 16px;
  }

  body .portfolio-table-wrapper .portfolio-table-container {
    padding: 24px;
  }

  .ai-container .ai-slide-wrapper .ai-slide-content {
    display: none;
  }


  .ai-container .ai-slide-wrapper .ai-slide-content {
    display: none;
  }

  .ai-container .ai-slide-wrapper {
    padding: 30px;
    text-align: center;
    height: 110px;
  }

  .ai-bording-page .ia-footer {
    padding-bottom: 15px;
  }

  .ai-bording-page .ai-full-height {
    height: auto;
  }

  .ai-container .ai-login-container,
  .ai-login-form {
    height: auto;
  }

  .ai-login-container {
    margin: 50px 0;
  }


  .ai-bording-page .ai-full-height {
    height: auto;
  }

  .ai-container .ai-login-container,
  .ai-login-form {
    height: auto;
  }

  .ai-login-container {
    margin: 50px 0;
  }
}

@media (max-width: 959px) {
  .portfolio-table-wrapper {
    padding-top: 58px;
  }

  .advisor .nav-wrap .subnavbar {
    width: 100%;
  }

  .details_wrap.alon {
    margin-top: 0;
  }

  .details_wrap.alon {
    align-items: flex-start;
    padding: 16px;
  }

  .ai-tabs.nav-tabs {
    padding: 0 16px;
  }

  .subnavbar-menu.navtab {
    position: static;
    padding: 0 16px;
  }

  #root {
    padding-top: 70px;
  }

  .ai-bording-page {
    margin-top: -70px;
  }

  .floathead .port_detail,
  .floathead {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .floathead .port_detail .details_wrap {
    width: 100%;
    align-items: flex-start;
  }

  .last_updated_text {
    padding-left: 0;
  }

  .floathead.alon,
  .main-header {
    width: 100%;
  }

  .main-header .page-header-wrapper {
    width: 100%;
    padding: 16px 8px;
    background: var(--White);
  }

  .ai-content-part.advisor .advisor-holder {
    padding-top: 55px;
    overflow: inherit;
  }

  .ai-content-part,
  #root>div {
    height: calc(100vh - 70px);
  }

  .stats-holder .details_wrap .infocard {
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .ai-content-wrapper {
    width: 100%;
  }

  .ai-login-container {
    margin: 0;
  }

  .ai-container .ai-login-wrapper {
    padding: 0;
  }

  .ai-row {
    grid-template-columns: 1fr !important;
    grid-gap: 0;
  }

  .ai-bording-page .container-fluid,
  .ai-bording-page .row {
    padding: 0;
    margin: 0;
  }

  .ai-container .ai-login-container {
    border-top: 15px var(--body-background-color) solid;
    border-bottom: 15px var(--body-background-color) solid;
    padding: 0;
    margin-bottom: 16px;
    flex: 1;
  }

  .ai-container .ai-login-container .ai-signup-wrapper {
    border-radius: 0px;
  }

  .ai-field-group,
  .ai-login-container .ai-two-column {
    row-gap: 8px;
  }

  .ai-container .ai-content-wrapper {
    flex: 0 0 100%;
  }

  .port_detail .subnavbar {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .navtab.subnavbar-menu {
    flex-direction: row;
    column-gap: 24px;
  }

  .stats-holder .details_wrap .infocard {
    max-width: 100%;
  }

  .subdetails-wrap,
  .stats-holder .details_wrap .infocard h3 {
    width: 100%;
    text-align: center;
  }

  body .portfolio-table-wrapper .portfolio-table-container {
    padding: 24px 8px;
  }

  .page-header {
    font-size: 18px;
  }

  .ai-content-wrapper {
    width: 100%;
  }

  .ai-login-container {
    margin: 0;
  }

  .ai-container .ai-login-wrapper {
    padding: 0;
  }

  .ai-row {
    grid-template-columns: 1fr !important;
    grid-gap: 0;
  }

  .ai-bording-page .container-fluid,
  .ai-bording-page .row {
    padding: 0;
    margin: 0;
  }

  .ai-container .ai-login-container {
    border-top: 15px var(--Peach) solid;
    border-bottom: 15px var(--Peach) solid;
    padding: 0;
    margin-bottom: 16px;
  }

  .ai-container .ai-login-container .ai-signup-wrapper {
    border-radius: 0px;
  }

  .ai-field-group,
  .ai-login-container .ai-two-column {
    row-gap: 8px;
    column-gap: 8px;
  }

  .ai-container .ai-content-wrapper {
    flex: 0 0 100%;
  }
}

@media (max-width: 680px) {

  .listbtn {
    flex-direction: column;
    row-gap: 8px;
  }

  .tiker {
    width: 100%;
  }

  .tiker .ai_input {
    width: 130px;
  }

  .saprator {
    width: 100%;
    height: 1px;
  }
}

.tiptext {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  td {
    white-space: nowrap;
  }

  p {
    margin: 0;
  }
}

.recharts-wrapper {
  width: 100% !important;


  .recharts-tooltip-wrapper {
    background: #fff;
    padding: 8px 16px;
    line-height: 16px;
    font-size: 12px;
    height: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  svg.recharts-surface {
    width: 100%;
    margin-bottom: 16px;
  }
}

.buttonbuy {
  p.infomessage {
    margin: 0;
    font-size: 14px;
  }
}

span.tooltipcon {
  display: inline-block;
  margin-left: 8px;
  margin-top: 4px;
}

.recharts-legend-wrapper {
  padding-left: 64px;
  padding-top: 24px;
}

.recharts-legend-wrapper .legend {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.backtouser {
  margin-right: 24px;
}

.middle-text {
  width: 100%;
  text-align: center;

  .ai-link {
    text-decoration: underline;
  }
}

.calendlysaprator {
  border-bottom: 1px solid var(--Black);
  text-align: center;
  position: relative;
  margin: 32px 0;

  &:after {
    content: "";
    z-index: 1;
    background: var(--White);
    width: 64px;
    margin-left: -32px;
  }

  span {
    z-index: 2;
    background: var(--Primary);
    color: var(--White);
    width: 32px;
    line-height: 34px;
    margin-left: -16px;
    border-radius: 100%;
  }

  span,
  &:after {
    position: absolute;
    display: inline-block;
    height: 32px;
    top: -16px;
    left: 50%;
    font-size: 14px;
  }
}