/* @font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url("./fonts/Inter-V.ttf") format("opentype");
}

@font-face {
  font-family: "InterV_Bold";
  font-weight: 1 1000;
  src: url("./fonts/Inter-V.ttf") format("truetype");
}

@font-face {
  font-family: "InterV";
  font-weight: 400;
  src: url("./fonts/Inter-V.ttf") format("opentype");
} */
@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Bold.woff2") format("woff2"),
    url("./fonts/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-SemiBold.woff2") format("woff2"),
    url("./fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Medium.woff2") format("woff2"),
    url("./fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.woff2") format("woff2"),
    url("./fonts/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Droid_Mono';
  src: url('fonts/Droid Sans Mono.woff2') format('woff2'),
    url('fonts/Droid Sans Mono.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

body {
  background-color: #fbece6;
  margin: 0px 0px 0px 0px;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  /* overflow-y: hidden;  */
}

* {
  box-sizing: border-box;
  font-family: Inter;
}

a {
  cursor: pointer;
}

.text_small {
  color: var(--Navy-Blue);
}

.mono {
  font-family: 'Droid_Mono' !important;
  text-align: right !important;
  font-weight: 700 !important;
}

.T_text-right {
  text-align: right !important;
}

.react-tooltip-lite {
  max-width: 300px !important;
}