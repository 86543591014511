.risk-cont {
  width: 100%;
  background-color: var(--White);
  padding: 0;
  flex-direction: column;

  .portfolio-table,
  .self_assembly.details_wrap {
    width: 100%;
  }
}

.contributio-table {
  th {
    padding: 8px 16px;

    div {
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
    }
  }
}

.contributio-table {
  table {
    max-width: 100%;
    width: 100%;
  }

  td {

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      font-family: 'Droid_Mono';
      color: var(--Navy-Blue);
    }
  }

  th,
  td {
    font-weight: 500;
    font-size: 14px;

    &:nth-child(1) {
      max-width: 50px;
    }

    td:nth-child(6) {
      color: var(--Navy-Blue);
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      text-align: left;
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      text-align: right;
    }
  }

  tfoot {
    background: var(--bg-primary);

    td {
      color: var(--Primary);
      font-weight: bold;
      padding: 16px;

      &:nth-child(2),
      &:nth-child(3) {
        text-align: right;
        font-family: 'Droid_Mono';
      }
    }
  }
}

.riskhead {
  display: flex;
  flex-wrap: wrap;
  padding: 32px 32px 0;
  width: 100%;

  .riskstate {
    h3 {
      color: var(--Secondary);
      font-size: 34px;
      margin: 0;
    }

    .lblrisk {
      div {
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
      }
    }
  }

  h2 {
    color: var(--Primary);
    font-size: 26px;
    line-height: 30px;
    display: flex;
    flex: 1;
  }
}

.riskmodes {
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 16px;

  div {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  span {
    display: inline-block;
  }

  .risk {
    width: 16px;
    height: 16px;
    background: var(--Primary);
  }

  .position {
    width: 16px;
    height: 16px;
    background: var(--Secondary);
    border-radius: 16px;
  }

  .vix {
    width: 32px;
    height: 4px;
    background: var(--Blue);
  }
}

.table_head {
  border: 1px var(--bg-gray) solid;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 48px;
  margin-bottom: 32px;

  .table_height {
    max-height: 300px;
  }

  h3 {
    background: var(--bg-primary);
    margin: 0;
    padding: 16px;
    color: var(--Primary);
    cursor: pointer;
    font-size: 22px;

    span {
      float: right;
    }
  }
}