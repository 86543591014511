.ShieldProtect-holder {
  width: 100%;
  height: 100vh;
  border-radius: 16px;
  background-color: var(--White);
}

.ShieldProtect-LeftDiv-lg-md {
  width: 500px;
  height: 500px;
}

.ShieldProtect-LeftDiv-sm-xs {
  margin-top: 20px;
  align-items: center;
}

.ShieldProtect-RightDiv-lg-md {
  margin-left: 500px;
  margin-top: -500px;
}

.ShieldProtect-RightDiv-sm-xs {
  margin: auto;
  min-width: 300px;
  align-items: center;
}

.ShieldProtection-TableWindow-lg {
  margin: auto;
  margin-top: -450px;
  margin-left: 950px;
}

.ShieldProtection-TableWindow-md {
  margin: auto;
  margin-top: -200px;
}

.ShieldProtection-TableWindow-sm {
  margin: auto;
  margin-top: -390px;
}

.ShieldProtection-TableWindow-xs {
  margin: auto;
  margin-top: -390px;
}

.graph_style {
  margin: auto;
  margin-top: 230px;
  margin-left: -180px;
}


.popupcontentpart {
  max-width: 850px;
  padding: 24px;
  height: 600px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}


.popupcontentpart .portfolio-table {
  padding: 0;
}

.popup-content .summary-header {
  padding: 8px 24px;
}

.summarybtn {
  display: flex;
  justify-content: center;
  column-gap: 16px;
  padding: 16px;
}

.agreement-holder {
  height: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 75px);
}