.product_buttons {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 32px;
  row-gap: 48px;
  column-gap: 48px;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #fff;
  overflow: auto;

  >div {
    width: 25%;
    min-width: 250px;
    max-width: 250px;
  }

  >.genius_product_buttons {
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    row-gap: 48px;
    column-gap: 48px;
    justify-content: center;
    align-items: center;

    a {
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 300px;

      .card_title {
        font-size: 18px;

        .title_text {
          color: var(--Primary);
        }
      }
    }
  }

  .card_title {
    font-family: InterV_Medium;
    font-size: 22px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.91px;
    text-align: center;
    color: var(--White);
    row-gap: 16px;
    column-gap: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;

    .title_text {
      color: var(--White);
      letter-spacing: 0;
      font-size: 16px;
    }

    .anticon {
      svg {
        height: 80px;
        width: auto;
        display: inline-block;
      }
    }

    img {
      height: 80px;
      width: auto;
      display: inline-block;
      margin: 18px 0;
    }
  }

  .card_title .card_title .title_text {
    margin: 0;
  }

}





.a-dashbord .product_buttons>div {
  opacity: 0.5;

  &:first-child {
    opacity: 1;
  }
}


.product_card {
  padding: 24px;
  width: 100%;
  display: block;
}

.product_card-purple {
  background-color: var(--Primary);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.product_card-orange {
  background-color: #feb875;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.product_card-black {
  background-color: #000000;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.product_card-peach {
  background-color: var(--Secondary);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.genius_product_card-purple {
  border-radius: 4px;
  background-color: var(--Primary);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}

.genius_product_card-orange {
  border-radius: 4px;
  background-color: var(--Secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.genius_product_card-black {
  border-radius: 4px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}

.product_card_vertical-purple {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background-color: var(--Primary);
  height: 350px;
  width: 300px;
  opacity: 0.9;
  margin: 30px;
}

.product_card_vertical-orange {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background-color: #feb875;
  height: 350px;
  width: 300px;
  opacity: 0.9;
  margin: 30px;
}

.product_card_vertical-black {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background-color: #000000;
  height: 350px;
  width: 300px;
  opacity: 0.9;
  margin: 30px;
}

.product_card_vertical-purple:hover,
.product_card_vertical-orange:hover,
.product_card_vertical-black:hover {
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  opacity: 1;
}



@media (max-width: 640px) {
  .card_title .anticon {
    padding: 0px !important;
  }

  .card_title img,
  .card_title .anticon svg {
    height: 70px;
  }

  .product_buttons .card_title {
    flex-direction: row;
    justify-content: space-between;
    row-gap: 16px;
    column-gap: 0;
    text-align: left;
  }

  .product_card {
    padding: 24px;
  }

  .card_title {
    font-size: 18px;
  }

  .product_buttons {
    padding: 24px;
    row-gap: 24px;
    column-gap: 24px;
    align-items: baseline;
    align-content: flex-start;
  }
}