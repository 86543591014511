.analyze-portfolio-graphs {
  display: flex;
  padding-left: 50px;
}

.analyze-portfolio-leftdiv-lg {
  padding-left: 40px;
}

.analyze-portfolio-leftdiv-lg-md {
  padding-left: 40px;
}

.analyze-portfolio-leftdiv-md-sm {
  padding-left: 40px;
}

.analyze-portfolio-leftdiv-sm-xs {
  padding-left: 5px;
  margin-top: 20px;
}

.risk-chart {
  padding: 0;
  width: 50%;
}

.risk-chart-lg-md {
  padding: 0;
  width: 70%;
}

.risk-chart-md-sm {
  padding: 0;
  width: 70%;
}

.risk-chart-sm {
  padding: 0;
  width: 100%;
}

.analyze-portfolio-rightdiv-lg {
  margin-top: 20px;
  margin-left: -400px;
}

.analyze-portfolio-rightdiv-md {
  margin-right: 300px;
}

.analyze-portfolio-rightdiv-lg-md {
  padding-left: 0px;
  margin-top: 20px;
  margin-left: -100px;
}

.analyze-portfolio-rightdiv-md-sm {
  padding: 0;
  margin-left: -100px;
}

.analyze-portfolio-rightdiv-sm-xs {
  margin-right: 200px;
  margin-left: -400px;
  margin-top: 250px;
  text-align: center;
}

.analyze-portfolio-buttons-lg-md {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-left: -80px;
}

.analyze-portfolio-button-div-lg-md {
  margin: auto;
  margin-top: 100px;
  width: 248px;
  text-align: center;
}

.view-shield-recommendations-holder {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  padding-right: 50px;
  padding-top: 30px;
}

.view-shield-recommendations-holder-xs {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  padding-right: 50px;
  padding-top: 30px;
}

.analyze-portfolio-rightbutton-lg-md {
  width: 248px;
  height: 60px;
  border-radius: 32px;
  background-color: #60034c;
}

.analyze-portfolio-rightbutton-md {
  width: 248px;
  height: 60px;
  margin-top: 50px;
  border-radius: 32px;
  background-color: #60034c;
}

.analyze-recommend-shield-button {
  /* width: 210px; */
  margin-top: -90px;
  height: 15px;
  padding: 30px 42px;
  border-radius: 32px;
  background-color: #60034c;
}

.analyze-recommend-shield-button-xs {
  height: 15px;
  padding: 20px 42px;
  border-radius: 32px;
  background-color: #60034c;
}

.analyze-recommend-button-text-lg {
  height: 20px;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--White);
}

.analyze-recommend-button-text-xs {
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: var(--White);
}

.analyze-portfolio * {
  box-sizing: border-box;
}

.port-analyzer {
  min-height: 100%;
  display: flex;
  width: 100%;
}

.analyze-portfolio {
  background-color: var(--White);
  box-sizing: border-box;
  padding: 32px;
  display: flex;
  width: 100%;
}

.analyze-portfolio-options {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  height: inherit;
}

.analyze-portfolio-column1 {
  width: 40%;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  justify-content: center;
}

.analyze-portfolio-column1>div,
.analyze-portfolio-column1 .screen {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.analyze-portfolio-column1 label,
.analyze-portfolio-column2 label {
  color: var(--Navy-Blue);
  font-size: 20px;
  font-weight: 600;
  display: block;
  align-items: center;
  text-align: center;
  width: 100%;
}

.analyze-portfolio-column2 {
  width: 60%;
}

.analyze-portfolio-container {
  /* background-color: #f2f2f2; */
  padding: 20px;
}

.new_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.col-50 {
  float: left;
  width: 100%;
  margin-top: 6px;
}

@media screen and (max-width: 992px) {
  .analyze-portfolio {
    padding: 24px;
  }
}

@media screen and (max-width: 768px) {
  .analyze-portfolio {
    flex-direction: column;
    row-gap: 48px;
    overflow: hidden;
  }

  .analyze-portfolio {
    padding: 0;
  }

  .analyze-portfolio-column1,
  .analyze-portfolio-column2 {
    width: 100%;
  }

  .analyze-portfolio-options {
    height: auto;
  }

  .analyze-portfolio-column2 .ProjectionApp {
    margin-top: 0px;
  }

  .analyze-portfolio-column1 label,
  .analyze-portfolio-column2 label {
    margin-top: 32px;
  }

  .ProjectionApp {
    margin-left: -32px;
  }
}

@media screen and (max-width: 650px) {
  .analyze-portfolio-column {
    width: 100%;
    margin-top: 10px;
  }
}