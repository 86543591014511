.shield-wrapper {
  background-color: var(--White);
  box-sizing: border-box;
  padding: 64px;
  box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.shield-wrapper * {
  box-sizing: border-box;
}

.confirmation-holder-2 {
  padding: 48px;
  border: 2px var(--Secondary) solid;
  border-radius: 16px;
  display: flex;
  column-gap: 32px;
}

.confirmation-holder-2 p {
  font-size: 18px;
  line-height: 26px;
  color: var(--Navy-Blue);
}

.confirmation-holder-2 a {
  font-size: 18px;
  color: var(--Secondary);
  text-decoration: underline;
  font-weight: normal;
}