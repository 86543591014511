.tab-content,
.tab-content>div {
  overflow: auto;
}

.portfolio-table {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 24px 32px;
  flex: 1;
}

.portfolio-table-2 {
  border-spacing: 6px;
}

.table_holder img {
  width: auto;
  max-width: none;
}

.portfolio-table::-webkit-scrollbar,
.portfolio-table-2::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--Peach);
  border-radius: 8px;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.54);
  margin-left: 8px;
}

.portfolio-table::-webkit-scrollbar-thumb,
.portfolio-table-2::-webkit-scrollbar-thumb {
  background-color: var(--Primary);
  border-radius: 8px;
}

.portfolio-table th {
  padding: 0 0 0;
  color: #60034c;

  font-weight: 700;
  letter-spacing: 1px;
  border-bottom: 1px solid #999;
  width: 1px;
  white-space: nowrap;
}

.portfolio-table-2 th {
  padding: 0 8px 12px 8px;
  padding-bottom: 5px;
  color: #60034c;

  font-weight: 700;
  letter-spacing: 1px;
  border-bottom: 3px solid #f0eef0;
  min-width: 50px;
  white-space: nowrap;
}

.portfolio-table th .stck_table_heading,
.portfolio-table-2 th .stck_table_heading {
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  padding: 10px 16px;
  color: #60034c;
}

.portfolio-table th:nth-child(3),
.portfolio-table-2 th:nth-child(3),
.portfolio-table th:nth-child(4),
.portfolio-table-2 th:nth-child(4),
.portfolio-table th:nth-child(7),
.portfolio-table-2 th:nth-child(7) {
  text-align: right;
}

.portfolio-table td {
  text-align: right;
  color: var(--Navy-Blue);

  font-size: 14px;
  width: 5%;
  padding: 10px 16px;
}

.portfolio-table-2 td {
  text-align: right;
  color: var(--Navy-Blue);

  font-size: 14px;
  width: 5%;
  padding: 10px 0 10px 0;
}

.portfolio-table td:nth-child(1),
.portfolio-table-2 td:nth-child(1),
.portfolio-table td:nth-child(2),
.portfolio-table-2 td:nth-child(2),
.portfolio-table td:nth-child(5),
.portfolio-table-2 td:nth-child(5),
.portfolio-table td:nth-child(6),
.portfolio-table-2 td:nth-child(6) {
  text-align: center;
}

.portfolio-table tbody tr:nth-child(even) {
  background: #fafafa;
}

.portfolio-table tbody tr:nth-child(even) {
  background: #fafafa;
}

.portfolio-table td:nth-child(6),
.portfolio-table-2 td:nth-child(6) {
  color: #38bb55;
  text-align: center;
}

.portfolio-table td:nth-child(7),
.portfolio-table-2 td:nth-child(7) {
  color: #38bb55;
  text-align: right;
}

.portfolio-table td:nth-child(1),
.portfolio-table-2 td:nth-child(1) .portfolio-table td:nth-child(4),
.portfolio-table-2 td:nth-child(4) {
  color: var(--Navy-Blue);
  font-weight: 700;
}

.portfolio-table tbody tr:nth-child(even) {
  background: #fafafa;
}

.sname_field,
.sname_field:focus,
.sname_field:hover {
  outline: none;
  border: none;
  padding: 0;
  text-align: left;
  color: #60034c;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  width: 100%;
  background: transparent;
  border-bottom: 1px solid #989898;
}

.portfolio-table tfoot td:nth-child(3) .sname_field {
  text-align: right;
}

.portfolio-table tfoot td:nth-child(4) {
  text-align: left;
}

.portfolio-table tfoot td:nth-child(4),
.portfolio-table tfoot td:nth-child(5),
.portfolio-table tfoot td:nth-child(6),
.portfolio-table tfoot td:nth-child(7) {
  border-bottom: none;
}

.footer_table {
  border-spacing: 16px 4px;
  align-items: center;
  color: #60034c;
  height: 60px;
  background-color: rgba(96, 3, 76, 0.2);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 30%;
}

.footer_table-2 {
  border-spacing: 16px 4px;
  align-items: center;
  padding-left: 20%;
  padding-right: 20%;
  color: #60034c;
  height: 50px;
  background-color: rgba(96, 3, 76, 0.2);
}

/* .nulla{

} */

@media screen and (max-width: 640px) {
  .footer_table-2 {
    padding: 0;
  }
}

.portfoliotable {
  text-align: center;
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
}

.portfoliotable tbody tr td:nth-child(2),
.portfoliotable tbody tr td:nth-child(3),
.portfoliotable tbody tr td:nth-child(4),
.portfoliotable tbody tr td:nth-child(6),
.portfoliotable tbody tr td:nth-child(7) {
  font-family: 'Droid_Mono';
}

.tiker {
  display: flex;
  column-gap: 16px;
}

.stocklist {

  th,
  td {
    padding: 16px;
    text-align: center;
  }
}