.plaid_page {
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  width: fit-content;
}

.plaid-card-lg {
  width: 365px;
  max-width: 365px;
  margin: 25px;
  position: relative;
}

.plaid-card-lg .plaid-card-header {
  background-color: #fbfbfb;
  padding: 0px 25px 24px 0;
  border-radius: 24px;
  position: relative;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
}

.plaid-card-lg a {
  display: inline-block;
}

.plaid-card-lg .plaid-summary-lg {
  padding: 10px 0px 12px;
  width: 332px;
  height: 110px;
  background: rgba(229, 139, 118, 0.1);
  border-radius: 15px;
  margin: 15px 0px 0px 24px;
}

.plaid-stock-total-lg img {
  height: 48.51px;
  width: auto;
  float: right;
}

.plaid-card-lg .plaid-summary-brokerage {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px 0px 24px;
}

.plaid-summary-brokerage p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.516923px;
  text-transform: uppercase;
}

/** For More than 2 Prtfolio and medium size screen**/

.plaid-card-md {
  width: 250px;
  background-color: #faefef;
  border-radius: 24px;
  margin: auto;
  margin: 30px 30px 30px 30px;
  padding: 30px 30px 30px 30px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.14);
}

.plaid-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  left: 0px;
  top: 0px;
  background: #e58b76;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
  margin-left: 28px;
}

.plaid-header .header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--White);
}

.plaid-summary-details {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  background: #fcf3f1;
  border-radius: 20px;
  width: 250px;
  /* padding: 30px; */
  padding: 20px 30px 10px 30px;
  margin: 8px 0px 0px 26px;
}

.plaid-summary-details label {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  text-transform: uppercase;
  color: var(--Navy-Blue);
  margin: 4px 0px;
}

.plaid-summary-details p {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  letter-spacing: 1px;
  color: var(--Navy-Blue);
  margin: 4px 0px;
}

.plaid-card-lg .plaid-select {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px 0px 24px;
  padding-top: 18px;
}

.select-btn {
  height: 35px;
  max-height: 35px;
  font-size: 13px;
  padding: 0px 50px;
  border-radius: var(--button-border-radius);
  appearance: none;
  border: none;
  font-family: "Inter";
  font-weight: 700;
  cursor: pointer;
  box-sizing: border-box;
  background-color: var(--Primary);
  color: var(--White);
}
.select-btn.disabled {
  opacity: 0.6;
}
.account-table {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
}
.ai-accountsummary {
  overflow: auto;
}
.account-table,
.ai-accountsummary,
.ai-accountsummary table {
  width: 100%;
  border-collapse: collapse;
}

.ai-accountsummary th,
.ai-accountsummary td {
  padding: 4px 24px;
  text-align: left;
  line-height: 18px;
  font-size: 14px;
}
.ai-accountsummary th {
  color: var(--Primary);
  text-transform: uppercase;
  background: var(--White);
  padding: 16px 24px;
}

.ai-accountsummary th:last-child,
.ai-accountsummary td:last-child {
  text-align: right;
}

.ai-accountsummary tr:nth-child(2n) {
  background: #fafafa;
}
body .MuiStepper-root {
  padding: 0;
}
