.plaidstock_page-holder {
  background-color: var(--White);
  padding: 48px 64px;
  border-radius: 32px 32px 0 0;
  /* box-shadow: 0px -2px 30px rgb(0 0 0 / 10%); */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1;
  flex-direction: column;
  grid-row-gap: 48px;
  row-gap: 48px;
}

@media (max-width: 992px) {
  .plaidstock_page-holder {
    padding: 24px;
  }
}

@media (max-width: 768px) {
  .plaidstock_page-holder {
    padding: 24px 8px;
  }
}

/* .addPortfolioButton{
      padding-right: 40px;
  } */