.ClientPortfolio,
.ClientPortfolio-2 {
  width: 100%;
  border-collapse: collapse;
  display: inline-block;
  overflow: auto;
}

.ClientPortfolio-2 {
  border-spacing: 6px;
}

.ClientPortfolio::-webkit-scrollbar,
.ClientPortfolio-2::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.54);
  margin-left: 8px;
}

.ClientPortfolio::-webkit-scrollbar-thumb,
.ClientPortfolio-2::-webkit-scrollbar-thumb {
  border-radius: 8px;
}

.ClientPortfolio th {
  padding: 0 0 24px 0;
  color: #60034c;
  font-weight: 700;
  letter-spacing: 1px;
  border-bottom: 1px solid #999;
  width: 1px;
  white-space: nowrap;
}

.ClientPortfolio-2 th {
  padding: 0 8px 12px 8px;
  padding-bottom: 5px;
  color: #60034c;
  font-weight: 700;
  letter-spacing: 1px;
  border-bottom: 3px solid #f0eef0;
  min-width: 50px;
  white-space: nowrap;
}

.ClientPortfolio th .stck_table_heading,
.ClientPortfolio-2 th .stck_table_heading {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 1px;
  color: #60034c;
  text-transform: uppercase;
}

.ClientPortfolio th:nth-child(3),
.ClientPortfolio-2 th:nth-child(3),
.ClientPortfolio th:nth-child(4),
.ClientPortfolio-2 th:nth-child(4) {
  text-align: right;
}

/* .ClientPortfolio th:nth-child(1),
.ClientPortfolio-2 th:nth-child(1), */
.ClientPortfolio th:nth-child(2),
.ClientPortfolio-2 th:nth-child(2) {
  text-align: left;
}

.ClientPortfolio td {
  text-align: center;
  color: #333333;
  font-size: 14px;
  width: 5%;
  padding: 10px 0 10px 0;
  /* text-transform: uppercase; */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
}

.ClientPortfolio td:last-child,
.ClientPortfolio td:last-child div {
  text-align: center;
}

.ClientPortfolio td:last-child button {
  float: none;
  margin: 0 auto;
}

.ClientPortfolio-2 td {
  text-align: center;
  color: #333333;
  font-size: 14px;
  width: 5%;
  padding: 10px 0 10px 0;
  /* text-transform: uppercase; */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
}

.ClientPortfolio td:nth-child(1),
.ClientPortfolio-2 td:nth-child(1) {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  color: #60034c;
  text-decoration: underline;
}

.ClientPortfolio td:nth-child(3),
.ClientPortfolio-2 td:nth-child(3),
.ClientPortfolio td:nth-child(4),
.ClientPortfolio-2 td:nth-child(4) {
  text-align: right;
}

.ClientPortfolio td:nth-child(1),
.ClientPortfolio-2 td:nth-child(1),
.ClientPortfolio td:nth-child(2),
.ClientPortfolio-2 td:nth-child(2) {
  text-align: left;
}

.ClientPortfolio tr:nth-child(2n),
.ClientPortfolio-2 tr:nth-child(2n) {
  background: #fafafa;
}

.buy-btn {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  padding-top: 10px;
  height: 35px;
  max-height: 35px;
  font-size: 13px;
  padding: 0px 30px;
  border-radius: var(--button-border-radius);
  appearance: none;
  border: none;
  font-family: "Inter";
  font-weight: 700;
  cursor: pointer;
  box-sizing: border-box;
  background-color: var(--Primary);
  color: var(--White);
}

.filter-inputs-min {
  border: 1px solid #666666;
  border-radius: var(--form-border-radius);
  background-color: var(--form-background-color);
  color: var(--Primary);
  font-size: var(--form-text-size);
  font-family: "Inter";
  padding: 10px;
  margin: 12px 16px 0px 16px;
  box-sizing: border-box;
}

.filter-inputs-max {
  border: 1px solid #666666;
  border-radius: var(--form-border-radius);
  background-color: var(--form-background-color);
  color: var(--Primary);
  font-size: var(--form-text-size);
  font-family: "Inter";
  padding: 10px;
  margin: 8px 16px 0px 16px;
  box-sizing: border-box;
}

.filter-btn {
  padding: 22px 48px;
  display: flex;
  flex-direction: row;
  align-self: center;
  font-size: 16px;
  border: none;
  line-height: 4px;
  border-radius: 40px;
  font-weight: 700;
  font-family: "Inter";
  color: var(--White);
  width: fit-content;
  cursor: pointer;
  background: var(--Primary);
}

.filter-label {
  color: var(--Primary);
  text-align: center;
  font-weight: 700;
  font-family: "Inter";
  font-size: 14px;
  margin: 0px;
}

.error-message {
  margin: 8px 16px;
  font-size: 10px;
  text-align: center;
  font-weight: 700;
  color: red;
}

.filter-paper {
  margin-top: 4px;
  margin-bottom: 16px;
  box-shadow: 0px 0px 0px (0, 0, 0, 0);
}

.chip {
  margin: 8px;
  font-size: 12px;
  font-weight: 700;
  font-family: "Inter";
  color: #e58b76;
  text-transform: capitalize;
}

.check-box {
  width: 13px;
  height: 14px;
  font-weight: 300;
  font-size: 14px;
  font-family: "Inter";
  line-height: 14px;
  text-transform: uppercase;
  color: #666666;
  text-align: left;
}

.check-box-labels {
  text-align: left;
  font-size: 14px;
  font-family: "Inter";
  padding: 10px;
  margin: 6px 16px 6px 16px;
}

.low {
  color: #38bb55;
}

.medium {
  color: #ffa654;
}

.high {
  color: #ed6663;
}

.filter-btn-checkbox {
  padding: 22px 48px;
  margin: 0px 16px;
  display: flex;
  flex-direction: row;
  align-self: center;
  font-size: 16px;
  border: none;
  line-height: 4px;
  border-radius: 40px;
  font-weight: 700;
  font-family: "Inter";
  color: var(--White);
  width: fit-content;
  cursor: pointer;
  background: var(--Primary);
}

.risk-transform {
  text-transform: capitalize;
}

.ai-form-group.cardpopup {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  padding: 16px;
}

.cardpopup label {
  margin: 0;
  display: flex;
  column-gap: 10px;
}

.cardpopup input {
  max-width: 150px;
  height: 30px;
}

.cardpopup .filter-label {
  width: 100%;
}