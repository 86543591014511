body {
  background-color: #fbece6;
}

.ProjectionApp {
  margin-top: 50px;
  background-color: var(--White);
  border: 1px solid #ffffff;
  position: relative;
  padding-left: 130px;
}

.axisX text {
  text-anchor: start;
  font-size: 12px;
  font-weight: 700;
  color: #60034c;
}

.tick {
  font-size: 12px;
  font-weight: 700;
  color: var(--Navy-Blue);
  opacity: 1;
}

.baseGridLine line {
  stroke: rgba(255, 255, 255, 0.5);
}

.tooltip {
  background-color: var(--White);
}

.contentBackground {
  background-color: var(--White);
}

.tooltip text {
  font-size: 12px;
  fill: #696969;
}

.tooltipLine {
  stroke: #60034c;
  stroke-dasharray: 3px;
  stroke-width: 1px;
}

.tooltipContent .contentTitle {
  font-weight: 700;
}

.tooltipLinePoint:nth-child(3) {
  fill: #38BB55;
}

.tooltipLinePoint:nth-child(4) {
  fill: #666666;
}

.tooltipLinePoint:nth-child(5) {
  fill: #60034c;
}

.tooltipLinePoint:nth-child(6) {
  fill: #e58b76;
}

.legendContainer {
  display: flex;
  justify-content: center;
  color: var(--White);
  padding: 24px 32px 0;
}

.checkbox {
  margin: 10px;
}

.porfoliovaluesline,
.porfoliovalues {
  position: absolute;
  height: 100%;
  left: 0;
  border-right: 2px var(--Primary) solid;
  width: 190px;
}

.porfoliovalues div {
  color: var(--Navy-Blue);
  text-align: right;
  width: 100%;
  padding-right: 20px;
}

.min_val {
  position: absolute;
  bottom: 20%;
}

.porfoliovaluesline {
  width: 100%;
  border: none;
}

.porfoliovaluesline:after {
  position: absolute;
  content: "";
  display: block;
  border: 1px #666 dashed;
  width: calc(100% - 220px);
  right: 30px;
  bottom: 23%;
}

.porfoliovaluesline:before {
  position: absolute;
  content: "";
  display: block;
  border: 1px #666 dashed;
  width: calc(100% - 220px);
  right: 30px;
  top: 22%;
}

.start_val {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.chartimg {
  z-index: 2;
  position: relative;
}

.max_val {
  position: absolute;
  top: 20%;
}

.port_val {
  color: var(--Primary);
  font-weight: 600;
  text-transform: uppercase;
  height: 100%;
  position: absolute;
  width: 20px;
  left: 0;
}

.port_date {
  color: var(--Primary);
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  width: calc(100% - 220px);
  right: 30px;
  bottom: 0;
  text-align: center;
}

.port_date:after {
  position: absolute;
  content: "";
  display: inline-block;
  height: 1px;
  background: var(--Primary);
  width: 100%;
  left: 0;
  margin-top: 10px;
}

.port_val:after {
  content: "";
  display: inline-block;
  width: 1px;
  background: var(--Primary);
  height: 100%;
  margin-left: 10px;
}

.port_val span {
  transform: rotate(-90deg);
  display: block;
  white-space: nowrap;
  width: 172px;
  position: absolute;
  left: -75px;
  top: 47%;
  background: #fff;
  padding: 0 12px;
}

.port_date span {
  display: inline-block;
  white-space: nowrap;
  background: #fff;
  padding: 0 12px;
  z-index: 2;
  position: relative;
}