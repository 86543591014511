.ai-form-group.addpaymentform {
    .ai-form-value {
        margin: 0;
    }
}

.accoutnpage {
    max-width: 100%;
    width: 405px;

    .add-portfolio-popup {
        width: 450px;
    }
}