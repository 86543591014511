.ai-login-form {
  overflow: auto;

  .ai-tabs {
    column-gap: 16px;
    display: flex;

    &.nav-tabs {
      .nav-item {
        padding: 16px;
        background: #F5F5F5;
        display: flex;
        border-radius: 4px;
        text-align: left;
        margin: 0px;
        width: 50%;
        font-size: 14px;
        color: var(--Black);
        line-height: 16px;
        flex-direction: column;
        align-items: center;

        &.active {
          background: var(--Secondary);
          color: var(--White);

          img {
            filter: brightness(0) invert(1);

          }

          // &:before {
          //   content: "\2713";
          //   color: var(--Secondary);
          //   background: var(--White);
          //   border: none;
          //   text-align: center;
          //   font-weight: 600;
          // }
        }

        &:after {
          content: none;
        }

        // &:before {
        //   content: "";
        //   width: 16px;
        //   height: 16px;
        //   border-radius: 100%;
        //   border: 1px var(--Black) solid;
        //   display: inline-block;
        //   margin-right: 8px;
        //   vertical-align: bottom;
        // }
      }
    }

  }

  .ai-forgot-password {
    margin: 0px;
    color: var(--Primary);
    font-weight: 600;
    font-size: 16px;
  }

  .ai-error-message {
    text-align: left;
    margin-top: 0px;
    width: 100%;
  }

  .ai-form-group {
    margin: 0px;
    flex-wrap: wrap;

    .ai-form-value {
      margin: 0;
    }
  }

  .ai-login-section {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .ai-field-group {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
  }

  .ai-link {
    font-size: 16px;
  }
}

.ai-signup-wrapper {
  .tab-content {
    margin-top: 24px;
  }
}

.ai-container {
  .container-fluid {
    box-sizing: border-box;
  }

  .ai-slide-wrapper {
    background-color: var(--Primary);
    padding: 60px 30px 30px 30px;
    overflow: hidden;
    box-sizing: border-box;

    .ai-slide-content {
      margin-top: 60px;
      text-align: center;

      .ai-slide {
        box-sizing: border-box;

        .ai-slide-image {
          width: 100%;
          text-align: center;

          img {
            max-width: 250px;
            display: inline-block;
          }
        }

        p {
          color: var(--White);
          font-size: 24px;
        }
      }
    }
  }

  .ai-login-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .ai-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px 15px 30px;
    box-sizing: border-box;
    height: 100%;

    .ai-signup-wrapper {
      width: 500px;
      max-width: 500px;
      background: var(--White);
      border-radius: 4px;
      padding: 32px;
      box-sizing: border-box;

      .ai-tabs {
        text-align: center;
        display: flex;
        padding: 0;
        column-gap: 16px;
      }

      .ai-signin {
        p {
          margin: 0 0 6px 0;
        }
      }

      h2 {
        margin: 0;
        text-align: left;
        font-size: 22px;
        font-weight: 600;
        text-transform: capitalize;
        color: var(--Primary);
      }
    }
  }
}

.popup-head {
  padding: 16px;
  background: var(--bg-primary);
  align-items: center;
  display: flex;

  img {
    max-height: 40px;
  }
}

.summarybtn {
  padding: 16px;
}

.popupcontent {
  h4 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 22px;
    color: var(--Primary);
  }

  p {
    margin-top: 0;
    margin-bottom: 30px;
    color: var(--Black);
    font-size: 14px;
    line-height: 22px;
  }
}