.parent {
  position: relative;
  display: flex;
  flex-direction: column;

  padding-top: 30px;
  height: calc(100vh - 275px);
  border-radius: 24px 24px 0 0;
  background-color: var(--White);
}

/* Element-01 */

.element-01 {
  position: relative;
}

.element-02 {
  position: relative;
  text-align: center;
  margin-top: 32px;
}

.element-03 {
  position: relative;
  margin-top: 12px;
  text-align: center;
}

.option-text {
  color: #b49eb1;
  font-size: 12px;
}

.option-text-sm {
  color: #b49eb1;
  font-size: 12px;
  padding-top: 220px;
}

.make-your-own-shield {
  color: #60034c;
  font-size: 16px;
  font-weight: 700;
}

.make-your-own-shield-sm {
  color: #60034c;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 40px;
}

a {
  text-decoration: none;
}
