.portfolio_page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  overflow: auto;
}

.portfolio-card-lg {
  position: relative;
}

.portfolio-card-lg .portfolio-card-header {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: #fbfbfb;
  border-radius: 16px;
  position: relative;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  padding: 0 16px 16px;
  max-width: 340px;
  margin: 8px;
  width: 100%;
}

.portfolio-bars {
  display: flex;
  column-gap: 24px;
}

.portfolio-card-lg a {
  display: inline-block;
}

.portfolio-card-lg .shield-summary-lg {
  background: rgba(229, 139, 118, 0.1);
  border-radius: 10px;
  display: flex;
  row-gap: 8px;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
}

.shield-summary-lg .portfolio-stock-total-lg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.portfolio-stock-total-lg .market-value {
  display: flex;
  flex-direction: column;
}

.market-value span {
  font-size: 12px;
  color: #666666;
  font-weight: 600;
}

.portfolio-stock-total-lg p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1px;
  letter-spacing: 0.516923px;
  color: #666666;
}

.portfolio-stock-total-lg text {
  font-family: "Inter";
  font-size: 23px;
  color: #333333;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.397692px;
}

.shield-summary-lg .shield-expiry {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  width: 100%;
  height: 24px;
  background: rgba(96, 3, 76, 0.2);
  border-radius: 10px;
}

.shield-expiry p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #60034c;
  margin: 0px;
}

.shield-summary-lg .shield-activate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  height: 24px;
  background: transparent;
  border-radius: 20px;
  border: 2px solid #60034c;
  margin-left: 30%;
  cursor: pointer;
}

.shield-activate img {
  margin-right: 5px;
}

.shield-activate p {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #60034c;
}

.portfolio-stock-total-lg img {
  height: 48.51px;
  width: auto;
  float: right;
}

.portfolio-card-lg .portfolio-summary-risk {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.portfolio-card-lg .summary-brokerage {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.summary-brokerage p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.516923px;
  text-transform: uppercase;
  margin: 0;
}

.portfolio-summary-risk p {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  margin: 0px 0 0 15px;
}

.portfolio-summary-risk img {
  min-width: 30px;
  height: 30px;
}

.portfolio-card-lg .portfolio-risk-bar {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 8px;
}

.portfolio-risk-bar p {
  font-size: 12px;
  display: flex;
  column-gap: 8px;
  margin: 4px 0;
  font-weight: 600;
}

.portfolio-risk-bar p div {
  display: inline-block;
}

.loader {
  margin: auto;
}

.portfolio-risk-bar .market-risk-progress {
  background-color: rgba(96, 3, 76, 0.1);
  border-radius: 5px;
  width: 100px;
  height: 5px;
  overflow: hidden;
}

.portfolio-risk-bar .market-risk-progress.inactive {
  display: none;
}

.portfolio-risk-bar img {
  height: 16px;
}

.market-risk-bar {
  background: linear-gradient(to left, #ffa654, #ffa654);

  border-radius: 5px;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  transition: 1s ease 0.3s;
}

.market-risk-bar p {
  line-height: 20px;
}

.progress-done-2 {
  background: linear-gradient(to left, #ed6663, #ed6663);
  border-radius: 5px;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.progress-done-2 p {
  line-height: 20px;
}

.progress-done-3 {
  background: linear-gradient(to left, #38bb55, #38bb55);
  border-radius: 5px;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.progress-done-3 p {
  line-height: 20px;
}

.progress-done-inactive {
  display: none;
}

.progress-done-inactive p {
  line-height: 20px;
}

/** For More than 2 Prtfolio and medium size screen**/

.portfolio-card-md {
  width: 250px;
  background-color: #faefef;
  border-radius: 24px;
  margin: auto;
  margin: 30px 30px 30px 30px;
  padding: 30px 30px 30px 30px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.14);
}

.summary-card-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  left: 0px;
  top: 0px;
  background: #e58b76;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
}

.summary-card-header .header-text {
  left: calc(50% - 111px / 2);
  top: calc(50% - 20px / 2);

  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--White);
}

@media (max-width: 768px) {
  .portfolio-card-lg .portfolio-card-header {
    margin: 8px 0;
  }
}

.loader {
  margin: auto;
}

.portfoliolist {
  width: 100%;
  text-align: left;
  border-spacing: 0;
}

.portfoliolist td,
.portfoliolist th {
  vertical-align: middle;
}

tr.portfolio-card-header td {
  border-bottom: 1px #E0D1DC solid;
  padding: 16px 8px;
  font-size: 14px;
  white-space: nowrap;
}

tr.portfolio-card-header td:first-child {
  min-width: 210px;
}

tr.portfolio-card-header {
  cursor: pointer;
  background: #fff;
  transition: all 0.2s ease-in-out;
}

tr.portfolio-card-header:hover {
  box-shadow: 0 4px 14px -6px rgb(24 39 75 / 20%),
    0 9px 44px -4px rgb(24 39 75 / 10%);
  position: relative;
  z-index: 2;
  border: none;
}

tr.portfolio-card-header td img {
  vertical-align: middle;
  margin-right: 8px;
}

.portfoliolist th {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.54;
  letter-spacing: 1px;
  padding: 10px 8px;
  color: #60034c;
  white-space: nowrap;
}

td.portfolio-summary-risk {
  width: 48px;
}

td.portfolioname {
  font-weight: 600;
  color: var(--Navy-Blue);
}

.CompareShieldsButton{
  background-color: #E58B76;
  height: 30px;
  border: 0.5px solid #E58B76;
  border-radius: 4px;
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
}

.CompareShieldsButton:hover{
  cursor: pointer;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.1);
}