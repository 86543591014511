.shield-wrapper {
  background-color: var(--White);
  box-sizing: border-box;
  padding-top: 24px;
  box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.shield-wrapper * {
  box-sizing: border-box;
}

.shield-wrapper .stock-details,
.shield-wrapper .stock-details.Mui-expanded {
  border: none;
  margin: 12px 0 12px 30px;
}

.shield-wrapper .stock-details:before {
  display: none;
}

.shield-wrapper .stock-details .MuiAccordionDetails-root {
  display: block;
}

.confirmation-holder {
  position: relative;
  display: flex;
  flex-direction: column;
}

.show-details {
  padding-left: 20px;
  width: 50%;
}

.show-details-text {
  color: #60034c;
  font-size: 15px;
  margin: 0;
}

@media (max-width: 940px) {

  .shield-wrapper .stock-details,
  .shield-wrapper .stock-details.Mui-expanded {
    margin: 12px 0 12px 10px;
  }
}