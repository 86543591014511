.logo-nav {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: var(--White);
  border-bottom: 1px solid #d1d1d1;
  padding: 0 24px;

  .navbar-logo {
    width: 200px;
  }
}



.navbar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  column-gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
}

.symbol {
  display: none;
}

.ITEM,
.ITEM-selected {
  display: block;
  text-align: center;
  color: var(--Black);
  padding: 16px 24px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  position: relative;
}

.advisor .ITEM,
.advisor .ITEM-selected {
  display: block;
  text-align: center;
  color: var(--Black);
  padding: 8px 16px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  position: relative;
  border-radius: 4px;
}

.user-selector .ITEM-selected {
  background: none;
  border-color: var(--Primary);
  color: var(--Secondary);
}

.advisor .ITEM-selected {
  background: var(--Primary);
  color: var(--White);
  border-radius: 4px;
}

.user-selector .ITEM:hover {
  background: none;
  color: var(--Navy-Blue);
  border-color: var(--Primary);
}

.ITEM:hover {
  color: var(--White);
  background: var(--Secondary);
}

.ITEM-selected {
  background: var(--Secondary);
  color: var(--White);
  /* border-bottom: 3px var(--Primary) solid; */
}

.ITEM-selected:after {
  background-color: var(--Primary);
}

.navbar-noti-3 .noti-icon-wrapper,
.navbar-noti-2 .noti-icon-wrapper,
.navbar-noti .noti-icon-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.navbar-noti-3 .noti-icon,
.navbar-noti-2 .noti-icon,
.navbar-noti .noti-icon {
  font-size: 32px;
}

.user_dropdone {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 9999;
  background: var(--White);
  box-shadow: 0 8px 28px rgba(24, 39, 75, 0.12), 0 18px 88px rgba(24, 39, 75, 0.14);
  min-width: 300px;
  display: none;
  overflow: auto;
}

.selecteduser {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 10px;
}

.selecteduser span {
  color: var(--Primary);
  font-weight: 500;
  flex: 1;
}

.search_input {
  background: #fafafb;
  display: flex;
  padding-left: 16px;
}

.search_input input {
  padding: 16px;
  background: none;
  border: none;
  flex: 1;
}

.search_input input:focus {
  border: none;
  outline: none;
}

span.noti-count {
  font-size: 8px;
  position: absolute;
  right: -8px;
  top: 2px;
  background: var(--Primary);
  color: var(--White);
  text-align: center;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 10px;
}

.navbar-noti {
  display: flex;
  width: 40px;
  display: none;
}

.navbar-account {

  >a {
    display: flex;
    // padding: 8px;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;

    .account-nav {
      max-width: 80%;
    }
  }
}


.navbar-2 {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #d1d1d1;
  padding: 0 12px;
}

.navbar-logo-2 {
  height: 50px;
  width: 140px;
  align-items: center;
  display: flex;
}

.navbar-menu-2 {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  flex: 1;
}

.navbar-menu-2 .user-selector .user {
  width: 180px;
}

.navbar-menu-2 .ITEM,
.navbar-menu-2 .ITEM-selected {
  padding: 16px;
  font-size: 16px;
}


.navbar-noti-2 .noti-icon-wrapper {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.navbar-noti-2 .noti-count {
  font-size: 12px;
}

// .navbar-account-2 {
//   width: 32px;
//   max-width: 32px;
// }

.navbar-account-2 a {
  display: flex;
  width: 49px;
  height: 49px;
  padding: 8px;
}

.navbar-3 {
  height: 70px;
  display: flex;
  padding: 0 64px 0 16px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #d1d1d1;
  background: var(--White);
  z-index: 998;
}

.nav-open .main_navbar {
  transform: skewX(0deg);
  left: 0px;
  opacity: 1;
}

.navbar-menu-3 .ITEM,
.navbar-menu-3 .ITEM-selected {
  padding: 16px;
  font-size: 16px;
  text-align: left;
  color: var(--White);
}

.navbar-menu-3 .ITEM-selected {
  color: var(--Primary);
}

.navbar-account-3 {
  width: 40px;
  height: 40px;
}

.nav-toggle {
  display: none;
  width: 40px;
  height: 40px;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: 15px;
  z-index: 999;
}

.nav-toggle span {
  width: 100%;
  height: 3px;
  background: #000;
  display: inline-block;
}

.nav-toggle span:nth-child(2) {
  margin: 4px 0;
}

.userarea {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-content: center;
  justify-items: center;
}

.userarea.advisor {
  // background: var(--Primary);
  text-align: center;
  height: 100%;
  padding: 0;
  margin: 8px 0;
  border-radius: 4px;
}

.userarea.advisor span.noti-count {
  background: var(--Secondary);
}

.monohead {
  text-align: right !important;
}

.navbar-noti-3 {
  margin-top: 8px;
  display: none;
}

.user-selector {
  display: flex;
  min-width: 695px;
}

.navbar-2 .user-selector {
  height: 50px;
}

.navbar-2 .user-selector ul li a {
  padding: 14px 0;
  white-space: nowrap;
}

.navbar-2 .user-selector ul li {
  padding: 0 10px;
}

.user-selector ul {
  background: var(--White);
  list-style: none;
  margin: 0px;
  padding: 0;
  display: block;
  display: flex;
  float: left;
  flex: 1;
  max-height: 300px;
  overflow: auto;
}

.user-selector ul.user_list {
  padding: 8px 0;
  display: block;
  width: 100%;
}

.user-selector ul li {
  float: left;
  padding: 0 22px;
}

.user-selector ul li a {
  display: inline-block;
  padding: 16px 0;
  border-bottom: 4px var(--White) solid;
  color: var(--Navy-Blue);
  font-weight: 500;
  font-size: 14px;
}

.user-selector ul li a.selected {
  border-bottom: 4px var(--Primary) solid;
  color: var(--Secondary);
}

.user-selector ul.user_list li,
.user-selector ul.user_list li a {
  width: 100%;
}

.user-selector ul.user_list li {
  padding: 0px 16px;
  width: 100%;
}

.user-selector ul.user_list li a {
  padding: 12px 0px;
  border-bottom: 1px rgba(102, 102, 102, 0.1) solid;
  display: flex;
  align-items: center;
}

.user-selector ul.user_list li:last-child a {
  border-bottom: none;
}

.user-selector ul.user_list li a .user_name {
  flex: 1;
}

.user_clientid {
  color: var(--Grey);
  font-size: 10px;
  font-weight: 600;
}

.user-selector .user {
  width: 230px;
  background: #f2c5ba;
  position: relative;
}

.user-selector .user:hover .user_dropdone {
  display: block;
}

@media (max-width: 1440px) {
  .user-selector ul li {
    padding: 0 10px;
  }

  .navbar-menu {
    column-gap: 0;
  }
}

@media (max-width: 959px) {
  .nav-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 99;
  }

  .nav-toggle {
    display: flex;
  }

  .logo-nav {
    max-width: 150px;
  }
}

@media (max-width: 640px) {
  .logo-nav {
    max-width: 100px;
  }
}

.subnavbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 32px;
}

@media (max-width: 959px) {
  .subnavbar {
    background: var(--Peach);
  }
}

.subnavbar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  column-gap: 48px;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  flex: 1;
}

.SUBITEM,
.SUBITEM-selected {
  display: block;
  text-align: center;
  color: #555;
  padding: 18px 0;
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  position: relative;
  white-space: nowrap;
}

.SUBITEM-selected {
  color: var(--Primary);
  border-bottom: 3px solid var(--Primary);
  border-radius: 0px;
}

.SUBITEM:hover {
  color: var(--Primary);
}

/* .SUBITEM-selected {
  background: var(--Secondary);
  color: var(--White);
} */

.SUBITEM-selected:after {
  background-color: var(--Primary);
}

.navbar-menu-3 {
  list-style: none;
  padding: 0;
}

.main_navbar {
  display: flex;
  flex: 1;
  align-content: center;
  align-items: center;
}

@media (max-width: 1279px) {
  .userarea.advisor {
    margin-right: -12px;
  }

  .user-selector .user {
    width: 160px;
  }

  .user-selector {
    min-width: 630px;
  }
}

@media (max-width: 959px) {
  .userarea.advisor {
    margin-right: 0px;
  }

  .user-selector {
    flex-direction: column;
    width: 100%;
    min-width: 0;
  }

  .user-selector ul li {
    padding: 0;
  }

  .user-selector ul li,
  .user-selector .user,
  .user-selector ul li a {
    width: 100%;
  }

  .user-selector ul li a {
    padding: 16px;
    text-align: left;
  }

  .user-selector ul {
    display: flex;
    flex-direction: column;
  }

  .main_navbar {
    display: block;
    position: fixed;
    margin: 0;
    padding: 24px 0;
    left: 0;
    top: 70px;
    width: 350px;
    max-width: 80%;
    background: var(--Secondary);
    z-index: 999;
    height: calc(100% - 70px);
    list-style: none;
    transform: skewX(-5deg);
    left: -400px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.5);
  }
}

.csvnavbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (max-width: 959px) {
  .csvnavbar {
    background: var(--Peach);
  }
}

@media (max-width: 480px) {
  .subnavbar {
    padding: 0;
    overflow: auto;
  }

  .subnavbar-menu {
    column-gap: 8px;
    width: 100%;
  }
}

.csvnavbar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  column-gap: 32px;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  flex: 1;
}

.feedbackcover,
.userprofile {
  box-shadow: 0 8px 28px rgba(24, 39, 75, 0.12), 0 18px 88px rgba(24, 39, 75, 0.14);
  list-style: none;
  padding: 24px 16px;
  position: absolute;
  right: 0px;
  background: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 0;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 0;
  display: none;
  top: 52px;

  li {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    a {
      column-gap: 16px;
      display: flex;
      align-items: center;
      padding: 8px;
      width: 100%;
      color: var(--Navy-Blue);
      border-bottom: 1px rgba(14, 27, 66, 0.07) solid;

      &:hover {
        background: #f5f5f5;
      }
    }

    &:last-child {
      a {
        border-bottom: none;
      }
    }
  }

}

.navbar-account,
.navbar-account-2,
.navbar-account-3 {
  &:hover {
    .userprofile {
      display: flex;
      z-index: 999;
    }
  }
}

.navbar-account,
.navbar-account-3,
.navbar-account-2 {
  display: flex;
}

.feedback {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  width: auto;
  align-items: center;
  padding: 8px 0;
  margin-right: 10px;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    color: var(--Primary);
    font-size: 10px;
    font-weight: bold;
  }
}

.feedbackcover {
  padding: 0px;
  width: 400px;
  max-width: 100%;

  .submmision {
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    display: none;
    align-items: center;

    &.active {
      display: flex;
    }

    h3 {
      color: var(--Navy-Blue);
      text-align: center;
      background: none;
      font-size: 16px;
    }
  }

  .feedform {
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &.closediv {
      display: none;

    }
  }

  .header {
    padding: 16px;
    background: var(--bg-primary);
    color: var(--Primary);
    display: flex;
    align-items: center;

    h3 {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      flex: 1;
    }
  }

  textarea {
    border: 1px #E6E6E6 solid;
    border-radius: 8px;
    width: 100%;
    height: 100px;
    padding: 8px 16px;
  }

  .error-message {
    display: flex;
    flex-direction: row-reverse;
    margin: 0;

    label {
      width: 100%;
      position: relative;
      color: var(--Error);
      text-align: right;
      font-size: 12px;
      flex: 1;
    }

    p {
      width: 100%;
      text-align: left;
      white-space: normal;
      color: var(--Error);
    }
  }

}

.feedbackcover.open {
  display: flex;
  z-index: 99;
}