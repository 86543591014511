.ai-backtest-container {
  background-color: var(--White);
  border-radius: 24px 24px 0 0;
  /* box-sizing: border-box; */
}

.ai-backtest-container .container,
.ai-backtest-container .container-fluid,
.ai-backtest-container .container-sm,
.ai-backtest-container .container-md,
.ai-backtest-container .container-lg,
.ai-backtest-container .container-xl {
  box-sizing: border-box;
}

.ai-backtest-container .tab-content {
  margin: 0;
}

.ai-backtest-container .tab-content .slick-slider,
.ai-date-selection {
  display: flex;
  align-content: center;
  align-items: center;
  column-gap: 32px;
  background: var(--White);
  padding: 16px 32px;
  border-bottom: 1px #E0D1DC solid;
}

.ai-date-selection .react-datepicker-wrapper {
  max-width: 200px;
}

.ai-backtest-container .ai-backtest-select select {
  appearance: none;
  border: none;
  padding: 0 0 0 0;
  font-size: 30px;
  position: relative;
}

.ai-backtest-container .ai-backtest-select select option {
  font-size: 14px;
}

.ai-backtest-container .ai-backtest-select .ai-backtest-select-arrow {
  position: absolute;
  top: 32px;
  right: 0px;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("../../images/arrowdown.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.ai-backtest-container .ai-backtest-events {
  margin-top: 30px;
}

.ai-backtest-graph {
  width: 100%;
  overflow: hidden;
  text-align: left;
  display: flex;
  flex: 1;
}

.ai-backtest-graph img {
  object-fit: cover;
}

.ai-backtest-tabs.ai-tabs.nav-tabs .nav-item button:after,
.ai-backtest-tabs.ai-tabs.nav-tabs .nav-item:after {
  bottom: -6px;
}

.ai-backtest-container .ai-date-selection {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin-top: 14px;
  align-items: center;
  margin: 0;
  justify-content: center;
  column-gap: 24px;
  flex-wrap: wrap;
}

.ai-backtest-container .ai-form-group {
  max-width: 300px;
  width: 100%;
}

.tab-content {
  display: flex;
  width: 100%;
  flex: 1;
}

.tab-content>div {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: var(--White);
}

.tab-content>div.active {
  display: flex;
}