.page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    flex-direction: column;
    row-gap: 32px;
  }
  .loadersvg{
    position: relative;
    top: 45%;
    width:64px;
    height:64px;
    display: grid;
    place-items: center;
  }
  .loadersvghalo{height:40px;width:130px;}
  .loader,.loaderhalo {
  display: block;
  position: relative;
  width: 50%;
  display: grid;
  place-items: center;
  }
  .loader::before,
  .loader::after,
  .loaderhalo::before,
  .loaderhalo::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  }
  .loader::before,.loaderhalo::before {
  height: 5px;
  width: 100px;
  background-color: #60034c;
  animation: loader-4 0.8s cubic-bezier(0, 0, 0.03, 0.9) infinite;
  }
  .loaderhalo::before {
    width: 130px;
    background-color: #081A66;
  }
  @keyframes loader-4 {
  0%, 44%, 88.1%, 100% {
    transform-origin: left;
  }
  
  0%, 100%, 88% {
    transform: scaleX(0);
  }
  
  44.1%, 88% {
    transform-origin: right;
  }
  
  33%, 44% {
    transform: scaleX(1);
  }
  }
