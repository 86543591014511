.selfassembly-page {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ai-selfassembly-container {
  background-color: var(--White);
  padding: 32px;
  box-sizing: border-box;
  height: calc(100% - 228px);
  display: flex;
  flex-direction: row;
  flex: 1;
  column-gap: 32px;
}

.ai-selfassembly-container * {
  box-sizing: border-box;
}

.ai-selfassembly-container .ai-selfassembly-select select {
  appearance: none;
  border: none;
  padding: 0 28px 4px 0;
  font-size: 20px;
  position: relative;
  border-radius: 0;
  font-weight: 700;
  color: var(--Navy-Blue);
  /* border-bottom: 1px dashed var(--Primary); */
}

.ai-selfassembly-container .ai-selfassembly-select select option {
  font-size: 16px;
}

.ai-selfassembly-container .ai-selfassembly-select .ai-selfassembly-select-arrow {
  position: absolute;
  top: 28px;
  right: 20px;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("../../images/arrowdown.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.ai-plan-details {
  display: flex;
  justify-content: space-around;
}

.ai-plan-details .ai-stocks {
  margin-right: 20px;
  margin-left: 20px;
  color: var(--Navy-Blue);
}

.ai-plan-details .ai-stocks:last-child {
  margin-right: 0;
}

.ai-plan-details .ai-stocks label {
  display: block;
  font-size: 14px;
  color: var(--Primary);
  font-weight: 700;
}

.ai-plan-details .ai-stocks p {
  margin: 4px 0 0 0;
  font-size: 28px;
  color: var(--Navy-Blue);
}

.ai-plan-details .ai-stocks p span {
  display: inline-block;
  margin-right: 4px;
}

.ai-selfassembly-container .ai-calculate-action {
  text-align: center;
}

.ai-selfassembly-container .ai-calculate-btn {
  height: 44px;
  max-height: 44px;
}

.ai-guaranteed-results {
  background-color: #efe6ed;
}

.ai-affordable-results {
  background-color: var(--Peach);
}

.ai-guaranteed-results,
.ai-affordable-results {
  border-radius: 4px;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.ai-guaranteed-table,
.ai-affordable-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.ai-guaranteed-results .ai-static-content,
.ai-affordable-results .ai-static-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  /* display: none; */
}

.ai-guaranteed-results .ai-header,
.ai-affordable-results .ai-header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 12px 0;
  text-align: center;
  color: var(--Primary);
  text-transform: uppercase;
}

.ai-guaranteed-results .ai-header span,
.ai-affordable-results .ai-header span {
  text-transform: none;
  font-size: 14px;
  display: inline-block;
  margin-left: 8px;
  font-family: "Inter";
  font-weight: 400;
  font-style: italic;
}

.ai-guaranteed-results .ai-helpText,
.ai-affordable-results .ai-helpText {
  margin: 0;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.32);
  text-align: center;
}

.ai-guaranteed-table .ai-card-header,
.ai-affordable-table .ai-card-header {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 4;
}

.ai-guaranteed-table .ai-card-header h4,
.ai-affordable-table .ai-card-header h4 {
  display: inline-block;
  margin: 0 0 8px 0;
  font-size: 24px;
  text-transform: uppercase;
  color: var(--Primary);
  position: relative;
  width: fit-content;
}

.ai-affordable-table .ai-card-header h4 {
  color: var(--Secondary);
}

.ai-guaranteed-table .ai-card-header h4:after,
.ai-affordable-table .ai-card-header h4:after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--Primary);
  opacity: 0.6;
}

.ai-guaranteed-table .ai-card-header p,
.ai-affordable-table .ai-card-header p {
  margin: 4px 0 0 0;
  font-size: 14px;
  font-style: italic;
}

.ai-selfassembly-container .regular.slider {
  display: flex;
  justify-content: space-between;
}

.ai-selfassembly-container .regular.slider div {
  flex: 0 0 48%;
}

.ai-selfassembly-container .slick-prev:before,
.ai-selfassembly-container .slick-next:before {
  color: #000;
}

.self_assembly.details_wrap {
  background: var(--White);
  padding: 24px 32px;
  border-bottom: 1px #E0D1DC solid;
}

@media (max-width: 1116px) {
  .ai-plan-details .ai-stocks p {
    font-size: 24px;
  }
}

@media (max-width: 1024px) {
  .ai-selfassembly-container .ai-calculate-btn {
    margin-top: 24px;
  }

  .ai-selfassembly-container {
    padding: 32px;
    column-gap: 24px;
    row-gap: 24px;
  }
}

@media (max-width: 768px) {

  .ai-guaranteed-table .ai-card-header h4,
  .ai-affordable-table .ai-card-header h4 {
    font-size: 18px;
  }

  .ai-selfassembly-container {
    padding: 30px 15px;
    flex-direction: column;
  }

  .ai-guaranteed-results .ai-helpText,
  .ai-affordable-results .ai-helpText {
    font-size: 28px;
  }
}